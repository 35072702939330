import React, {useContext, useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';

import MaterialTable, {MTableToolbar} from 'material-table';
import makeStyles from '@mui/styles/makeStyles';
import buttons from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.js';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Card from '../../components/Card/Card';
import CardIcon from '../../components/Card/CardIcon';
import {Alert, Grid, MenuItem, Select, Snackbar, Typography} from "@mui/material"
import  { EvStation } from '@mui/icons-material';

import useAPI from '../../utils/useAPI';
import { AuthContext } from '../Auth/AuthContext';
import { Permissions } from '../Auth/Permissions';
import EVChargerService from '../../services/EVChargerService';
import LocationService from '../../services/LocationService';
import CardHeader from "../../components/Card/CardHeader"
import Loading from '../../components/Loading';
import { Modules, isModuleDisabled } from 'config';

const useStyles = makeStyles(() => ({
  ...buttons,
}));

export default function EVChargers() {
  const api = useAPI();
  const navigate = useNavigate();
  const classes = useStyles();
  const { hasPermission } = useContext(AuthContext);

  const [locations, setLocations] = useState(null);
  const [selectedLocationId, setSelectedLocationId] = useState('');
  const [evChargers, setEVChargers] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState(null);

  useEffect(() => {
    if (!hasPermission(Permissions.VIEW_EV_CHARGERS) || isModuleDisabled(Modules.EVChargers)) {
      navigate('/');
    }
  }, [])

  useEffect(() => {
    EVChargerService.init(api);
    LocationService.init(api);
    fetchLocations();
  }, [api])

  const fetchLocations = async () => {
    try {
      const locations = await LocationService.getLocations();
      setLocations(locations);
    } catch (err) {
      alert(err);
    }
  };

  useEffect(() => {
    if (locations?.length > 0) {
      setSelectedLocationId(locations[0].id);
    }
  }, [locations]);

  const handleLocationChange = (event) => {
    const locationId = event.target.value;
    setSelectedLocationId(locationId);
  };

  useEffect(() => {
    if (selectedLocationId !== '') {
      fetchEVChargers();
    }
  }, [selectedLocationId]);

  const fetchEVChargers = async () => {
    try {
      const res = await EVChargerService.fetchChargers(selectedLocationId);
      const transformed = res.map(transformCharger);
      setEVChargers(transformed);
    } catch (err) {
      alert(err);
    }
  }

  const statusToColor = (status) => {
    switch (status.summary) {
      case 'Available':
      case 'Charging Complete':
        return '#C8E6C9';
      case 'Charging':
        return '#BBDEFB';
      case 'Error':
        return '#FFCDD2';
      case 'Offline':
      default:
        return '#E0E0E0';
    }
  }

  const transformCharger = (c) => {
    const spotName = c.parking_spot?.name;
    const zoneName = c.parking_spot?.parking_zone?.name;
    const lotName = c.parking_spot?.parking_lot.name;
    const name = c.carousel ?
      `${c.carousel?.nickname}: ${c.pallet_number}` :
      `${lotName}: ${zoneName ? zoneName + ': ' : ''}${spotName}`;
    return {
      name,
      status: c.status.summary,
      description: c.status.description,
      color: statusToColor(c.status),
    }
  }

  const handleCloseSnackbar = () => {
    setSnackbarMessage(null);
  }

  return (
    <GridContainer>
      <div className={classes.cardContentLeft}></div>
      {locations && locations.length > 1 ?
        <Grid container alignItems="center" className={classes.customToolbarContainer}>
          <Grid item>
            <Typography variant="h6" className={classes.label}>
              Select Location:
            </Typography>
          </Grid>
          <Grid item>
            <Select
              label="Select Location"
              value={selectedLocationId}
              onChange={handleLocationChange}
            >
              {locations.map((loc) => {
                return <MenuItem key={loc.id} value={loc.id}>{loc.name}</MenuItem>
              })}
            </Select>
          </Grid>
        </Grid> : null}

      <GridItem xs={12}>
        <Card>
          <Snackbar open={!!snackbarMessage} autoHideDuration={60000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
          {evChargers ?
            <MaterialTable
              title='EV Chargers'
              columns={[
                { title: 'Name', field: 'name' },
                { title: 'Status', field: 'status' },
                { title: 'Description', field: 'description' },
              ]}
              data={evChargers}
              components={{
                Toolbar: (props) => (
                  <div style={{ alignItems: 'center', paddingRight: '0%' }}>
                    <CardHeader color='primary' icon>
                      <CardIcon color='rose'>
                        <EvStation style={{ color: 'white' }} />{' '}
                      </CardIcon>
                    </CardHeader>
                    <MTableToolbar {...props} />
                  </div>
                ),
              }}
              options={{
                search: true,
                actionsColumnIndex: 0,
                debounceInterval: 500,
                sorting: true,
                pageSizeOptions: [10, 20, 50, 100],
                pageSize: 10,
                headerStyle: { padding: '16px' },
                rowStyle: rowData => ({
                  backgroundColor: rowData.color,
                  fontWeight: 400,
                  fontSize: 15,
                })
              }}
            /> :
            <Loading/>
          }
        </Card>
      </GridItem>
    </GridContainer>
  );
}

import { useRef, useEffect, useContext } from 'react';
import axios from 'axios';
import { getConfig } from 'config';
import { useAuth0 } from '@auth0/auth0-react';
import { PinContext } from '../components/PinLogin/PinContext';
const { apiOrigin = 'http://localhost:3001', audience, valetMode } = getConfig();
export const baseUrl = `${apiOrigin}/api/`;

export default () => {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();
  const  { pin } = useContext(PinContext);
  const api = useRef(
    axios.create({
      baseURL: baseUrl,
    }),
  );
  useEffect(() => {
    const currentAPI = api.current;
    const requestInterceptor = async (config) => {
      if (valetMode) {
        if (pin) {
          config.headers['X-STAK-PIN'] = pin;
        } else {
          console.error('No PIN available in valet mode.');
        }
        return config;
      } else {
        let token, accessToken;
        const authParams = {
          authorizationParams: {
            audience,
            scope: 'read:users',
            redirect_uri: window.location.origin,
          },
          detailedResponse: true,
        };
        try {
          token = await getAccessTokenSilently(authParams);
        } catch (err) {
          if (err.message.includes('Consent required')) {
            token = await getAccessTokenWithPopup(authParams);
          } else {
            console.error(err);
            throw err;
          }
        }
        accessToken = token?.access_token || token;
        config.headers.authorization = `Bearer ${accessToken}`;
        config.cancelToken = axios.CancelToken.source().token;
        if (localStorage.getItem('apiToken') === accessToken) {
          return config;
        }
        try {
          // Test the access token
          await axios.get(`${apiOrigin}/api/status`, {
            headers: {
              authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
          });
          localStorage.setItem('apiToken', accessToken);
        } catch (e) {
          console.error(e);
          alert(`Authentication failure: ${e.message}`);
        }
        return config;
      }
    }
    const requestInterceptorId = currentAPI.interceptors.request.use(requestInterceptor);
    return () => {
      currentAPI.interceptors.request.eject(requestInterceptorId);
    };
  }, [pin]);
  return api.current;
};

import React, {useContext, useEffect, useState} from 'react';
import useAPI from '../../utils/useAPI';
import MUIDataTable from 'mui-datatables';
import makeStyles from "@mui/styles/makeStyles"
import LocationService from "../../services/LocationService"
import GridContainer from "../../components/Grid/GridContainer"
import GridItem from "../../components/Grid/GridItem"
import Card from "../../components/Card/Card"
import Check from "@mui/icons-material/Check"
import CardIcon from "../../components/Card/CardIcon"
import LocationOnIcon from '@mui/icons-material/LocationOn';
import {Typography} from "@mui/material"
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import AccessibleSharpIcon from '@mui/icons-material/AccessibleSharp';
import BoltSharpIcon from '@mui/icons-material/BoltSharp';
import AirportShuttleSharpIcon from '@mui/icons-material/AirportShuttleSharp';
import HomeSharpIcon from '@mui/icons-material/HomeSharp';
import DirectionsBikeSharpIcon from '@mui/icons-material/DirectionsBikeSharp';
import {GetVehicleLabel, getVehicleLabelText} from '../Vehicle/VehicleUtils';
import {AuthContext} from '../Auth/AuthContext';
import {Permissions} from '../Auth/Permissions';
import {useNavigate} from 'react-router-dom';
import Loading from '../../components/Loading';
import { Modules, isModuleDisabled } from 'config';

const useStyles = makeStyles((theme) => ({
  customToolbarContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'start',
    marginBottom: '25px',
  },
  label: {
    marginRight: theme.spacing(2),
    fontSize: 16,
  },
}));

export function SurfaceSpots() {
  const api = useAPI();
  const { hasPermission } = useContext(AuthContext);
  const navigate = useNavigate();
  const classes = useStyles();
  const [locations, setLocations] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState('');
  const [spots, setSpots] = useState(null);

  useEffect(() => {
    if (!hasPermission(Permissions.VIEW_SURFACE_SPOTS) || isModuleDisabled(Modules.SurfaceSpots)) {
      navigate('/');
    }
    LocationService.init(api);
    fetchLocations();
  }, [api]);

  useEffect(() => {
    if (locations?.length > 0) {
      setSelectedLocation(locations[0].id);
    }
  }, [locations]);

  useEffect(() => {
    if (selectedLocation) {
      fetchLocationWithSpots(selectedLocation);
    }
  }, [selectedLocation]);

  const fetchLocations = async () => {
    try {
      const locations = await LocationService.getLocations();
      setLocations(locations);
    } catch (err) {
      alert(err);
    }
  };

  const handleLocationChange = (event) => {
    const locationId = event.target.value;
    setSelectedLocation(locationId);
  };

  const fetchLocationWithSpots = async (locationId) => {
    try {
      const location = await LocationService.getLocationWithSpots(locationId, true);
      const spots = LocationService.transformLocationToSpots(location);
      setSpots(spotsToRows(spots));
    } catch (err) {
      alert(err);
    }
  }

  const spotsToRows = (spots) => {
    const rows = [];
    for (const spot of spots) {
      rows.push({
        id: spot.id,
        index: spot.index,
        lot: spot.lot.name,
        //zone: spot.zone || '',
        spot: spot.name,
        assigned_resident: spot?.plan?.membership?.user_group?.name || '',
        parked_vehicle: spot.active_parking_session.length === 0 ? ''
          : <GetVehicleLabel vehicle={spot.active_parking_session[0].vehicle} />,
        vehicle_text: spot.active_parking_session.length === 0 ? ''
          : getVehicleLabelText(spot.active_parking_session[0].vehicle),
        is_ev: !!spot.is_ev,
        is_accessible: !!spot.is_accessible,
        is_extra_wide: !!spot.is_extra_wide,
        is_covered: !!spot.is_covered,
        is_bicycle: !!spot.is_bicycle,
      })
    }
    return rows;
  }

  const isConvertibleToInt = (str) => {
    return !isNaN(str) && !isNaN(parseInt(str));
  }

  const spotSorter = (order = 'asc') => (row1, row2) => {
    const a = row1.data;
    const b = row2.data;
    const isANumber = isConvertibleToInt(a);
    const isBNumber = isConvertibleToInt(b);
    let result;

    if (isANumber && isBNumber) {
      // Both are numbers: sort numerically
      result = parseInt(a) - parseInt(b);
    } else if (isANumber) {
      // Only 'a' is a number, it comes first
      result = -1;
    } else if (isBNumber) {
      // Only 'b' is a number, it comes first
      result = 1;
    } else {
      // Both are non-numbers: sort alphabetically
      result = a.localeCompare(b);
    }
    return order === "desc" ? -result : result;
  }

  const boolColOptions = {
    customBodyRender: (value, tableMeta) => {
      if (!value) return '';
      const columnName = tableMeta.columnData.name;
      switch (columnName) {
        case 'is_ev':
          return <BoltSharpIcon />;
        case 'is_accessible':
          return <span style={{ paddingLeft: '20px' }}>
            <AccessibleSharpIcon />
          </span>
        case 'is_extra_wide':
          return <span style={{ paddingLeft: '25px' }}>
            <AirportShuttleSharpIcon />
          </span>
        case 'is_covered':
          return <span style={{ paddingLeft: '20px' }}>
            <HomeSharpIcon />
          </span>
        case 'is_bicycle':
          return <span style={{ paddingLeft: '15px' }}>
            <DirectionsBikeSharpIcon />
          </span>
        default:
          return <Check />;
      }
    },
    filter: true,
    filterType: 'checkbox',
  };

  const columns = [
    {
      label: 'Lot',
      name: 'lot',
      options: {
        sortThirdClickReset: true,
      },
    },
    {
      label: 'Spot',
      name: 'spot',
      options: {
        filter: false,
        sortThirdClickReset: true,
        sortCompare: (order) => {
          return spotSorter(order);
        }
      },
    },
    {
      label: 'Assigned resident',
      name: 'assigned_resident',
      options: {
        filter: false,
      }
    },
    {
      label: 'Parked vehicle',
      name: 'parked_vehicle',
      options: {
        filter: false,
        sort: false,
      }
    },
    {
      name: 'vehicle_text',
      options: {
        display: 'excluded',
        filter: false,
      }
    },
    {
      label: 'EV?',
      name: 'is_ev',
      options: {
        ...boolColOptions,
        filterOptions: {
          names: ['EV'],
          logic(value, filters, row) {
            return !row[5]; // 6th column
          },
        },
      },
    },
    {
      label: 'Accessible?',
      name: 'is_accessible',
      options: {
        ...boolColOptions,
        filterOptions: {
          names: ['Accessible'],
          logic(value, filters, row) {
            return !row[6];
          },
        },
      },
    },
    // {
    //   label: 'Extra wide?',
    //   name: 'is_extra_wide',
    //   options: boolColOptions,
    // },
    {
      label: 'Covered?',
      name: 'is_covered',
      options: {
        ...boolColOptions,
        filterOptions: {
          names: ['Covered'],
          logic(value, filters, row) {
            return !row[7];
          },
        },
      },
    },
    {
      label: 'Bicycle?',
      name: 'is_bicycle',
      options: {
        ...boolColOptions,
        filterOptions: {
          names: ['Bicycle'],
          logic(value, filters, row) {
            return !row[8];
          },
        },
      },
    },
  ];
  const options = {
    serverSide: false,
    filter: true,
    print: false,
    download: false,
    rowsPerPage: 10,
    rowsPerPageOptions: [10, 20, 50, 100],
    selectableRowsHeader: false, // Hide checkbox in header
    selectableRowsHideCheckboxes: true, // Hide checkbox for every row
    customSearch: (searchQuery, currentRow) => {
      let isFound = false;
      currentRow.forEach(col => {
        if (col && col.toString().toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0) {
          isFound = true;
        }
      });
      return isFound;
    },
  };

  const tableOptions = {
    filterType: 'checkbox',
    customToolbar: () => {
      return (locations?.length > 1 ?
        <Grid container alignItems="center" className={classes.customToolbarContainer}>
          <Grid item>
            <Typography variant="h6" className={classes.label}>
              Select Location:
            </Typography>
          </Grid>
          <Grid item>
            <Select
              label="Select Location"
              value={selectedLocation}
              onChange={handleLocationChange}
            >
              {locations.map((loc) => {
                return <MenuItem key={loc.id} value={loc.id}>{loc.name}</MenuItem>
              })}
            </Select>
          </Grid>
        </Grid> : null
      );
    },
  };

  return (
    <GridContainer>
      <div className={classes.cardContentLeft}></div>
      <GridItem xs={12}>
        <Card>
          {spots ?
            <MUIDataTable
              title={
                <div>
                  <CardIcon color='rose'>
                    <LocationOnIcon style={{ color: 'white' }} />{' '}
                  </CardIcon>
                  <Typography variant='h6'>Surface Spots</Typography>
                </div>
              }
              data={spots}
              columns={columns}
              options={{...options, ...tableOptions}}
            /> :
            <Loading/>
          }
        </Card>
      </GridItem>
    </GridContainer>
  );
}

import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';
import { useIdleTimer } from 'react-idle-timer'
import { getConfig } from 'config';
const { valetMode, valetLogoutOnIdleSecs } = getConfig();
export const PinContext = createContext(null);

export const PinProvider = ({ children }) => {
    const [pin, setPin] = useState('');
    const [isInvalidPin, setInvalidPin] = useState(false);
    const [isAuthorized, setAuthorized] = useState(false);
    const [idleReset, setIdleReset] = useState(false); // toggle to notify pin pad to reset
    const logout = () => {
        setPin('');
        setAuthorized(false);
    }

    const notifyUnauthorized = () => {
        setPin('');
        setAuthorized(false);
        setInvalidPin(true);
        setTimeout(() => {
            setInvalidPin(false);
        }, 2000);
    }

    const notifyAuthorized = () => {
        setAuthorized(true);
    }

    const onIdle = () => {
        if (isAuthorized) {
            logout();
        }
        setIdleReset(!idleReset);
    }

    if (valetMode && valetLogoutOnIdleSecs > 0) {
        useIdleTimer({ onIdle, timeout: valetLogoutOnIdleSecs * 1000 });
    }

    return (
        <PinContext.Provider value={{ pin, setPin, logout, isInvalidPin, notifyAuthorized, notifyUnauthorized, isAuthorized, idleReset }}>
            {children}
        </PinContext.Provider>
    );
};

PinProvider.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired
};

import Axios from 'axios';
const Utils = require('../utils/Utils');

let api = Axios;
const init = async (axiosApi) => {
  api = axiosApi;
};

/**
 * We only support a single carousel assignment for now.
 * For simplicity, convert any assignment to `carousel_id` and `carousel_name` property on the vehicle.
 */
const convertCarouselAssignmentToProperty = (vehicle) => {
  if (vehicle?.carousel_assignments[0]) {
    vehicle = {
      ...vehicle,
      carousel_id: vehicle.carousel_assignments[0].carousel_id,
      carousel_name: `${vehicle.carousel_assignments[0].carousel.nickname} (${vehicle.carousel_assignments[0].carousel.location.name})`,
    }
  }
  delete vehicle.carousel_assignments;
  return vehicle;
}

const searchVehicles = async (pageSize, page, search, orderBy, orderDirection) => {
  try {
    const response = await api.get(
      '/admin/vehicles',
      { params: { pageSize, page, search, orderBy, orderDirection } },
    );
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
};

const searchVehiclesWithSessions = async (pageSize, page, search, orderBy, orderDirection) => {
  try {
    const response = await api.get(
        '/admin/vehicle-search-with-sessions',
        { params: { pageSize, page, search, orderBy, orderDirection } },
    );
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
};

const getUsersForVehicle = async (vehicleId) => {
  try {
    const response = await api.get(`/admin/vehicles/${vehicleId}/users`);
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
}

const getVehicle = async (id) => {
  try {
    const response = await api.get(`/admin/vehicles/${id}`);
    return convertCarouselAssignmentToProperty(response.data);
  } catch (error) {
    throw Utils.convertApiError(error);
  }
};

const deleteVehicle = async (id) => {
  try {
    const response = await api.delete(
      '/admin/vehicles',
      { data: { id: id } },
    );
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
};

const upsertVehicle = async (data) => {
  try {
    const response = await api.put('/admin/vehicles', data);
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
};

const getVehicleMakes = async (search) => {
  try {
    const response = await api.get(`/vehicles/makes/${search}`);
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
};

const getVehicleModelsByMake = async (make) => {
  try {
    const response = await api.get(`/vehicle-models-by-make/${make}`);
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
};

const getVehicleYearsByModel = async (modelId) => {
  try {
    const response = await api.get(`/vehicle-years-by-model/${modelId}`);
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
}

const getVehicleModel = async (modelId, year) => {
  try {
    const response = await api.get(`/vehicle-models/${modelId}?year=${year}`);
    return response.data;
  } catch (error) {
    throw Utils.convertApiError(error);
  }
}

const VehicleService = {
  init,
  getVehicle,
  searchVehicles,
  searchVehiclesWithSessions,
  getUsersForVehicle,
  deleteVehicle,
  upsertVehicle,
  getVehicleMakes,
  getVehicleModelsByMake,
  getVehicleYearsByModel,
  getVehicleModel,
};

export default VehicleService;

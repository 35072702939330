import React from 'react';
import MUIDataTable from 'mui-datatables';
import { toUSNationalPhoneNumber } from '../../utils/Utils';

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardIcon from 'components/Card/CardIcon.js';
import makeStyles from '@mui/styles/makeStyles';
import modalStyle from 'assets/jss/material-dashboard-pro-react/modalStyle.js';
// apis
import SubscriptionBookingService from 'services/SubscriptionBookingService';
import useAPI from 'utils/useAPI';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Close from '@mui/icons-material/Close';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';

import moment from 'moment';

const useStyles = makeStyles(modalStyle);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />;
});

export default function SubscriptionBooking() {
  const api = useAPI();
  const [data, setData] = React.useState(null);
  const [sendData] = React.useState(null);
  const [modal, setModal] = React.useState(false);
  const [amount, setAmount] = React.useState(0.0);
  const [message, setMessage] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  const fetchSubscriptionBooking = () => {
    SubscriptionBookingService.getSubscriptionBookings().then((res) => {
      const d = transform(res);
      setData(d);
    });
  };
  React.useEffect(() => {
    SubscriptionBookingService.init(api);
    fetchSubscriptionBooking();
  }, [api]);

  const filterString = (value) => {
    return value ? value : 'NA';
  };

  const cancelSubscription = async (transaction) => {
    SubscriptionBookingService.updateCurrentSubscriptionBooking(transaction).then(() => {
      fetchSubscriptionBooking();
    });
  };

  const handleAmount = (event) => {
    setAmount(parseInt(event.target.value));
  };
  const handleMessage = (event) => {
    setMessage(event.target.value);
  };

  const makeRefund = () => {
    console.log('msg', amount, message);
    SubscriptionBookingService.makeRefund(amount, message).then((res) => {
      console.log('!23', res);
    });
  };

  function transform(session) {
    let transformed_subscriptions = session;
    transformed_subscriptions = transformed_subscriptions.map((ses) => {
      return {
        id: ses.id,
        name: filterString(ses.user.name),
        phone: toUSNationalPhoneNumber(ses.user.phone) + ' ',
        vehicle_name: ses.vehicle_name,
        plan_name: ses.plan ? ses.plan.plan_name : 'NA',
        amount: ses.plan ? ses.plan.amount : 'NA',
        start_date:
          ses.plan && ses.start_date ? moment(ses.start_date).format('MM/DD/YYYY hh:mm A') : 'NA',
        end_date:
          ses.plan && ses.end_date ? moment(ses.end_date).format('MM/DD/YYYY hh:mm A') : 'NA',
        next_billing_date:
          ses.plan && ses.next_billing_date
            ? moment(ses.next_billing_date).format('MM/DD/YYYY hh:mm A')
            : 'NA',
        actions: 'NA',
      };
    });
    return transformed_subscriptions;
  }
  const options = {
    filter: true, // Hide filters
    print: false, // Hide print
    download: false, // Hide download
    selectableRowsHeader: false, // Hide checkbox in header
    selectableRowsHideCheckboxes: true, // Hide checkbox for every row
  };
  let columns = [
    {
      label: 'Name',
      name: 'name',
      options: {
        filter: false,
      },
    },
    {
      label: 'Phone',
      name: 'phone',
      options: {
        filter: false,
      },
    },
    {
      label: 'Vehicle',
      name: 'vehicle_name',
      options: {
        filter: false,
      },
    },
    {
      label: 'Plan',
      name: 'plan_name',
    },
    {
      label: 'Status',
      name: 'plan_status',
    },
    {
      label: 'Amount ($)',
      name: 'amount',
      options: {
        filter: false,
      },
    },
    {
      label: 'Start Date',
      name: 'start_date',
      options: {
        filter: false,
      },
    },
    {
      label: 'End Date',
      name: 'end_date',
      options: {
        filter: false,
      },
    },
    {
      label: 'Next Billing Date',
      name: 'next_billing_date',
      options: {
        filter: false,
      },
    },
    {
      label: 'Actions',
      name: 'actions',
      options: {
        filter: false,
      },
    },
  ];

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          {data && (
            <MUIDataTable
              title={
                <div>
                  <CardIcon color='rose'>
                    <CollectionsBookmarkIcon style={{ color: 'white' }} />{' '}
                  </CardIcon>
                  <Typography variant='h6'>SUBSCRIPTION TRANSACTIONS</Typography>
                </div>
              }
              data={data}
              columns={columns}
              options={options}
            />
          )}
          <Dialog
            classes={{
              root: classes.center,
              paper: classes.modal,
            }}
            open={modal}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => {
              setModal(false);
            }}
            aria-labelledby='modal-slide-title'
            aria-describedby='modal-slide-description'
          >
            <DialogTitle id='classic-modal-slide-title' className={classes.modalHeader}>
              <IconButton
                className={classes.modalCloseButton}
                key='close'
                aria-label='Close'
                color='inherit'
                onClick={() => {
                  setModal(false);
                }}
                size='large'
              >
                <Close className={classes.modalClose} />
              </IconButton>
              <div className={classes.modalTitle}>Enter Refund Amount</div>
            </DialogTitle>
            <DialogContent id='modal-slide-description' className={classes.modalBody}>
              <TextField
                id='outlined-multiline-static'
                label='Refund Amount'
                defaultValue='0'
                fullWidth
                type='number'
                value={amount}
                onChange={handleAmount}
                margin='dense'
              />
              <TextField
                id='outlined-multiline-static'
                label='Message'
                multiline
                rows={4}
                defaultValue=''
                fullWidth
                onChange={handleMessage}
                margin='dense'
              />
            </DialogContent>
            <DialogActions className={classes.modalFooter + ' ' + classes.modalFooterCenter}>
              <Button
                onClick={() => {
                  setModal(false);
                  setAmount(0);
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  if (amount !== 0) {
                    setModal(false);
                    console.log(amount, message);
                    makeRefund();
                    setAmount(0);
                  }
                }}
                color='primary'
              >
                Send
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={open}
            onClose={() => {
              setOpen(false);
            }}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
          >
            <DialogTitle id='alert-dialog-title'>{'Confirm Cancel Subscription'}</DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-description'>
                Are you sure you want to cancel this subscription?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setOpen(false);
                }}
              >
                Disagree
              </Button>
              <Button
                onClick={() => {
                  cancelSubscription(sendData);
                  setOpen(false);
                }}
                color='primary'
                autoFocus
              >
                Agree
              </Button>
            </DialogActions>
          </Dialog>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

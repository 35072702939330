import React, {useContext} from 'react';
import MUIDataTable from 'mui-datatables';

import makeStyles from '@mui/styles/makeStyles';
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardIcon from 'components/Card/CardIcon.js';
import {IconButton, Tooltip, Typography} from '@mui/material';
import {cardTitle} from 'assets/jss/material-dashboard-pro-react.js';
import LocationService from 'services/LocationService';
import useAPI from 'utils/useAPI';
import buttons from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.js';
import {useNavigate} from 'react-router-dom';
import tableStyles from 'assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import Check from "@mui/icons-material/Check"
import {AuthContext} from '../Auth/AuthContext';
import {Permissions} from '../Auth/Permissions';

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    buttons,
  },
  ...tableStyles,
};

const useStyles = makeStyles(styles);

export default function ReactTables() {
  const api = useAPI();
  const navigate = useNavigate();
  const { hasPermission } = useContext(AuthContext);
  const [data, setData] = React.useState(null);
  const [params, setParams] = React.useState({
    pageSize: 10,
    page: 1,
    search: null,
    orderBy: null,
    orderDirection: null
  });
  const isSystemAdministrator = hasPermission(Permissions.SYSTEM_ADMINISTRATION);

  const fetchLocationList = (params) => {
    LocationService.getLocationList(params.pageSize, params.page, params.search, params.orderBy, params.orderDirection)
      .then((res) => {
        setParams({
          ...params,
          page: res.page,
          count: res.total
        })
        const d = transform(res.results);
        setData(d);
      })
      .catch();
  };

  React.useEffect(() => {
    if (!hasPermission(Permissions.VIEW_LOCATIONS)) {
      navigate('/');
    }
    LocationService.init(api);
    fetchLocationList(params);
  }, [api]);

  function transform(locations) {
    let transformed_data = locations;
    transformed_data = transformed_data.map((loc) => {
      return {
        id: loc.id,
        name: loc.name,
        latitude: loc.latitude,
        longitude: loc.longitude,
        zipcode: loc.zipcode,
        is_park_with_geolocation: loc.is_park_with_geolocation,
        parking_radius_ft: loc.parking_radius_ft,
        total_spaces: loc?.total_spaces || 'NA',
        available_spaces: loc?.available_spaces || 'NA',
        ...(isSystemAdministrator && {
          actions: (
            <>
              <div display='flex'>
                <Tooltip title='Update Location' aria-label=''>
                  <IconButton
                    onClick={async () => {
                      navigate('/location/edit/' + loc.id);
                    }}
                    size='large'
                  >
                    <EditIcon color='primary' />
                  </IconButton>
                </Tooltip>
              </div>
            </>
          ),
        })
      };
    });
    return transformed_data;
  }

  const classes = useStyles();

  const centerHeader = ({index, ...column}) => {
    return (
      <th key={index} style={{ textAlign: 'center' }}>
        {column.label}
      </th>
    )
  }
  const centerColumn = (value) => {
    return (
      <div style={{ textAlign: 'center' }}>
        {value}
      </div>
    );
  }

  const columns = [
    ...(isSystemAdministrator ? [{
      label: 'Actions',
      name: 'actions',
      options: {
       sort: false
      }
    }] : []),
    {
      label: 'Name',
      name: 'name',
    },
    {
      label: 'Latitude',
      name: 'latitude',
    },
    {
      label: 'Longitude',
      name: 'longitude',
    },
    {
      label: 'ZIP Code',
      name: 'zipcode',
    },
    {
      label: 'Geolocation parking',
      name: 'is_park_with_geolocation',
      options: {
        customHeadRender: centerHeader,
        customBodyRender: (value) => {
          return (
            <div style={{ textAlign: 'center' }}>
              {value === 1 ? <Check /> : ""}
            </div>
          );
        }
      }
    },
    {
      label: 'Parking radius (ft.)',
      name: 'parking_radius_ft',
      options: {
        sort: false,
        customHeadRender: centerHeader,
        customBodyRender: centerColumn,
      }
    },
    {
      label: 'Total Spaces',
      name: 'total_spaces',
      options: {
        sort: false,
        customHeadRender: centerHeader,
        customBodyRender: centerColumn,
      }
    },
    {
      label: 'Available Spaces',
      name: 'available_spaces',
      options: {
        sort: false,
        customHeadRender: centerHeader,
        customBodyRender: centerColumn,
      }
    },
  ];
  const options = {
    serverSide: true,
    filter: false, // Hide filters
    print: false, // Hide print
    download: false, // Hide download
    selectableRowsHeader: false, // Hide checkbox in header
    selectableRowsHideCheckboxes: true, // Hide checkbox for every row
    onTableChange: (action, tableState) => {
      let sortCol = tableState.sortOrder?.name;
      const newParams = {
        pageSize: tableState.rowsPerPage,
        page: tableState.page + 1,
        search: tableState.searchText,
        orderBy: sortCol,
        orderDirection: sortCol ? tableState.sortOrder?.direction : null
      };
      if (["changePage", "search", "filterChange", "sort", "changeRowsPerPage"].includes(action)){
        setParams(newParams);
        fetchLocationList(newParams);
      }
    },
  };

  if (isSystemAdministrator) {
    options.customToolbar = () => {
      return (
        <Tooltip title='Add Location' aria-label=''>
          <IconButton
            onClick={() => {
              navigate('/location/add');
            }}
            size='large'
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
      );
    }
  }

  return (
    <GridContainer>
      <div className={classes.cardContentLeft}></div>
      <GridItem xs={12}>
        <Card>
          {data && (
            <MUIDataTable
              title={
                <div>
                  <CardIcon color='rose'>
                    <LocationOnIcon style={{ color: 'white' }} />{' '}
                  </CardIcon>
                  <Typography variant='h6'>Locations</Typography>
                </div>
              }
              data={data}
              columns={columns}
              options={{...options, count: params.count}}
            />
          )}
        </Card>
      </GridItem>
    </GridContainer>
  );
}

import React, { useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Paper from '@mui/material/Paper';
import Grow from '@mui/material/Grow';
import Hidden from '@mui/material/Hidden';
import Popper from '@mui/material/Popper';
import Divider from '@mui/material/Divider';
import { useNavigate } from 'react-router-dom';
import { PinContext } from '../PinLogin/PinContext';

import Person from '@mui/icons-material/Person';
import RefreshIcon from '@mui/icons-material/Refresh';
import Button from 'components/CustomButtons/Button.js';
import { useAuth0 } from '@auth0/auth0-react';

import styles from 'assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js';
import {getConfig} from "../../config"

const useStyles = makeStyles(styles);
const { trainingVideoUrl = 'https://google.com/', valetMode } = getConfig();

export default function HeaderLinks() {
  const { logout } = useAuth0();
  const  { logout: pinLogout } = useContext(PinContext);
  const navigate = useNavigate();
  const [openProfile, setOpenProfile] = React.useState(null);

  const handleClickProfile = (event) => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };

  const handleCloseProfile = () => {
    setOpenProfile(null);
  };

  const handleNotificatons = () => {
    setOpenProfile(null);
    navigate('/admin/notifications');
  };

  const handleAbout = () => {
    setOpenProfile(null);
    navigate('/admin/about');
  };

  const handleOnClickProfile = () => {
    setOpenProfile(null);
    navigate('/admin/profile');
  };

  const handleTrainingVideo = () => {
    setOpenProfile(null);
    window.open(trainingVideoUrl, '_blank');
  };

  const handleLogout = () => {
    setOpenProfile(null);
    if (valetMode) {
      pinLogout();
      navigate('/');
    } else {
      logout({
        logoutParams: {
          returnTo: window.location.origin
        }
      });
    }
  };
  const classes = useStyles();
  const dropdownItem = classNames(classes.dropdownItem, classes.primaryHover);
  const wrapper = classNames({
    [classes.wrapperRTL]: false,
  });
  const managerClasses = classNames({
    [classes.managerClasses]: true,
  });

  const menuItems = [];
  if (!valetMode) {
    menuItems.push(<MenuItem key={'item-01'} onClick={handleOnClickProfile} className={dropdownItem}>
      Profile
    </MenuItem>);
    menuItems.push(<Divider key={'item-02'} light />);
    menuItems.push(<MenuItem key={'item-03'} onClick={handleNotificatons} className={dropdownItem}>
      Notifications
    </MenuItem>);
    menuItems.push(<Divider key={'item-04'} light />);
    menuItems.push(<MenuItem key={'item-05'} onClick={handleTrainingVideo} className={dropdownItem}>
      {'Training video'}
    </MenuItem>);
    menuItems.push(<Divider key={'item-06'} light />);
  }
  menuItems.push(<MenuItem key={'item-07'} onClick={handleAbout} className={dropdownItem}>
    About
  </MenuItem>);
  menuItems.push(<Divider key={'item-08'} light />);
  menuItems.push(<MenuItem key={'item-09'} onClick={handleLogout} className={dropdownItem}>
    Log out
  </MenuItem>);

  return (
    <div className={wrapper}>
      <div className={managerClasses}>
        <Button
          color='transparent'
          aria-label='Person'
          justIcon
          aria-owns={openProfile ? 'profile-menu-list' : null}
          aria-haspopup='true'
          onClick={handleClickProfile}
          className={classes.buttonLink}
          muiClasses={{
            label: '',
          }}
        >
          <Person className={classes.headerLinksSvg + ' ' + classes.links}/>
          <Hidden mdUp implementation='css'>
            <span onClick={handleClickProfile} className={classes.linkText}>
              Profile
            </span>
          </Hidden>
        </Button>
        {valetMode &&
          <Button
            color='transparent'
            aria-label='app-refresh'
            justIcon
            aria-haspopup='false'
            onClick={() => location.reload()}
            className={classes.buttonLink}
          >
            <RefreshIcon/>
          </Button>
        }
        <Popper
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          placement='bottom'
          className={classNames({
            [classes.popperClose]: !openProfile,
            [classes.popperResponsive]: true,
            [classes.popperNav]: true,
          })}
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps} id='profile-menu-list' style={{ transformOrigin: '0 0 0' }}>
              <Paper className={classes.dropdown}>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role='menu'>
                    {menuItems}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}

HeaderLinks.propTypes = {
  rtlActive: PropTypes.bool,
};

import React, { useContext } from 'react';
import {AuthContext} from './views/Auth/AuthContext';
import {Permissions} from './views/Auth/Permissions';
import { getConfig, Modules, isModuleDisabled } from './config';

import Users from './views/User/Users';
import Reservations from './views/Reservation/Reservations';
import Locations from './views/Location/Locations';
import Carousels from './views/Carousel/Carousels';
import QRReaders from './views/QRReader/QRReaders';
import Cameras from './views/Camera/Cameras';
import Pins from './views/UserVehiclePins/Pins';
import Transactions from 'views/Tables/Transactions.js';
import ParkingSessions from 'views/Tables/ParkingSessions';
import { SurfaceSpots } from './views/SurfaceParking/SurfaceSpots';
import { ParkingQueue } from './views/ParkingQueue/ParkingQueue';
import Discount from 'views/Tables/Discount';
import FeeStructure from 'views/Tables/FeeStructure';
import EVChargers from './views/EVCharger/EVChargers';
import GroupIcon from '@mui/icons-material/Group';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ListAltIcon from '@mui/icons-material/ListAlt';
import FiberPinIcon from '@mui/icons-material/FiberPin';
import ReceiptIcon from '@mui/icons-material/Receipt';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import CameraIcon from '@mui/icons-material/Camera';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import UnsubscribeIcon from '@mui/icons-material/Unsubscribe';
import ConfirmationNumberSharpIcon from '@mui/icons-material/ConfirmationNumberSharp';
import SettingsIcon from '@mui/icons-material/Settings';
import GridViewIcon from '@mui/icons-material/GridView';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import  { EvStation } from '@mui/icons-material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

// Unused pages
import SubscriptionTransactions from 'views/Tables/SubscriptionTransactions';
import Vehicles from './views/Vehicle/Vehicles';
import Ticket from './views/Tables/Ticket';
import Subscription from './views/Tables/Subscription';
import SubscriptionBooking from './views/Tables/SubscriptionBooking';
import Configuration from './views/Tables/Configuration';

export const sidebarRoutes = () => {
  const { hasPermission } = useContext(AuthContext);
  const { valetMode } = getConfig();
  return [
    {
      path: '/users',
      name: 'Users',
      mini: 'US',
      component: <Users />,
      icon: GroupIcon,
      layout: '/admin',
      hidden: valetMode || !hasPermission(Permissions.VIEW_USERS),
    },
    {
      path: '/reservations',
      name: 'Reservations',
      mini: 'US',
      component: <Reservations />,
      icon: CalendarMonthIcon,
      layout: '/admin',
      hidden: valetMode || !hasPermission(Permissions.VIEW_RESERVATIONS) || isModuleDisabled(Modules.Reservations),
    },
    {
      path: '/parking-queue',
      name: 'Parking Queue',
      mini: 'VH',
      icon: FormatListNumberedIcon,
      component: <ParkingQueue/>,
      layout: '/admin',
      hidden: !hasPermission(Permissions.QUEUE_VEHICLE) || isModuleDisabled(Modules.ParkingQueue),
    },
    {
      path: '/parking-sessions',
      name: 'Parking Sessions',
      mini: 'PS',
      icon: LocalParkingIcon,
      component: <ParkingSessions />,
      layout: '/admin',
      hidden: !hasPermission(Permissions.VIEW_PARKING_SESSIONS),
    },
    {
      path: '/surface-spots',
      name: 'Surface Spots',
      mini: 'VH',
      icon: GridViewIcon,
      component: <SurfaceSpots/>,
      layout: '/admin',
      hidden: !hasPermission(Permissions.VIEW_SURFACE_SPOTS) || isModuleDisabled(Modules.SurfaceSpots),
    },
    {
      path: '/ev-chargers',
      name: 'EV Chargers',
      mini: 'VH',
      icon: EvStation,
      component: <EVChargers/>,
      layout: '/admin',
      hidden: !hasPermission(Permissions.VIEW_EV_CHARGERS) || isModuleDisabled(Modules.EVChargers),
    },
    {
      path: '/carousels',
      name: 'Carousels',
      mini: 'CA',
      icon: ListAltIcon,
      component: <Carousels />,
      layout: '/admin',
      hidden: !valetMode && !hasPermission(Permissions.VIEW_CAROUSELS),
    },
    {
      path: '/cameras',
      name: 'Cameras',
      mini: 'PS',
      icon: CameraIcon,
      component: <Cameras />,
      layout: '/admin',
      hidden: !hasPermission(Permissions.VIEW_CAMERAS) || isModuleDisabled(Modules.Cameras),
    },
    {
      path: '/qr-reader',
      name: 'QR Readers',
      mini: 'PS',
      icon: QrCodeScannerIcon,
      component: <QRReaders />,
      layout: '/admin',
      hidden: valetMode || !hasPermission(Permissions.SYSTEM_ADMINISTRATION) || isModuleDisabled(Modules.QRReaders),
    },
    {
      path: '/location',
      name: 'Locations',
      mini: 'VH',
      icon: LocationOnIcon,
      component: <Locations />,
      layout: '/admin',
      hidden: valetMode || !hasPermission(Permissions.VIEW_LOCATIONS),
    },
    {
      path: '/pins',
      name: 'User Vehicle Pins',
      mini: 'CA',
      icon: FiberPinIcon,
      component: <Pins />,
      layout: '/admin',
      hidden: valetMode || !hasPermission(Permissions.SYSTEM_ADMINISTRATION) || isModuleDisabled(Modules.UserVehiclePins),
    },
    {
      path: '/vehicles',
      name: 'Vehicles',
      mini: 'VH',
      icon: LocalShippingIcon,
      component: <Vehicles />,
      layout: '/admin',
      hidden: true,
    },
    {
      path: '/transactions',
      name: 'Transactions',
      mini: 'CA',
      icon: ReceiptIcon,
      component: <Transactions />,
      layout: '/admin',
      hidden: true,
    },
    {
      path: '/discount',
      name: 'Discount',
      mini: 'PS',
      icon: LocalAtmIcon,
      component: <Discount />,
      layout: '/admin',
      hidden: isModuleDisabled(Modules.Discount),
    },
    {
      path: '/subscription-transactions',
      name: 'Subscription Transactions',
      mini: 'CA',
      icon: ReceiptIcon,
      component: <SubscriptionTransactions />,
      layout: '/admin',
      hidden: true,
    },
    {
      path: '/fee-structure',
      name: 'Fee Structure',
      mini: 'CA',
      icon: MonetizationOnIcon,
      component: <FeeStructure />,
      layout: '/admin',
      hidden: true,
    },
    {
      path: '/ticket',
      name: 'Ticket',
      mini: 'CA',
      icon: ConfirmationNumberSharpIcon,
      component: <Ticket />,
      layout: '/admin',
      hidden: true,
    },
    {
      path: '/user-subscription-bookings',
      name: 'Subscription Bookings',
      mini: 'CA',
      icon: CollectionsBookmarkIcon,
      component: <SubscriptionBooking />,
      layout: '/admin',
      hidden: true,
    },
    {
      path: '/subscription-plans',
      name: 'Manage Subscription',
      mini: 'CA',
      icon: UnsubscribeIcon,
      component: <Subscription />,
      layout: '/admin',
      hidden: true,
    },
    {
      path: '/configuration',
      name: 'Additional Fees',
      mini: 'CA',
      icon: SettingsIcon,
      component: <Configuration />,
      layout: '/admin',
      hidden: true,
    },
  ];
}

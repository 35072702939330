import React, { useEffect, createRef, useContext } from 'react';
// core components
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardHeader from 'components/Card/CardHeader.js';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import VehicleService from 'services/VehicleService';
import UserService from '../../services/UserService';
import useAPI from 'utils/useAPI';
import {useNavigate} from 'react-router-dom';
import MaterialTable, {MTableToolbar} from 'material-table';
import PropTypes from "prop-types"
import Check from '@mui/icons-material/Check';
import Close from '@mui/icons-material/Close';
import {AuthContext} from '../Auth/AuthContext';
import {Permissions} from '../Auth/Permissions';
import { getConfig } from 'config';

export default function VehicleTable({ user = null, group = null, refreshData }) {
  const api = useAPI();
  const config = getConfig();
  const navigate = useNavigate();
  const tableRef = createRef();
  const { hasPermission, isSameUser, isSameGroup } = useContext(AuthContext);
  const addlVehicleId = config.addlVehicleId;

  useEffect(() => {
    VehicleService.init(api);
  }, [api]);

  useEffect(() => {
    tableRef.current && tableRef.current.onQueryChange();
  }, [refreshData]);

  const fetchDataFn =
    (query) =>
        new Promise((resolve, reject) => {
          if (user?.id) {
            resolve({
              data: user.group.vehicles,
              page: 0,
              totalCount: user.group.vehicles.length,
            });
          } else if (group?.id) {
            resolve({
              data: group.vehicles,
              page: 0,
              totalCount: group.vehicles.length,
            });
          } else {
            VehicleService.searchVehicles(
              query.pageSize,
              query.page + 1,
              query.search,
              query.orderBy ? query.orderBy.field : null,
              query.orderDirection,
            )
              .then((res) => {
                resolve({
                  data: res.results.map((vehicle) => {
                    return {
                      id: vehicle.id,
                      year: vehicle.year,
                      make: vehicle.make,
                      model: vehicle.model,
                      plate: vehicle.plate,
                      color: vehicle.color,
                      state: vehicle.state,
                      zip: vehicle.zip,
                      type: vehicle.type,
                      addl_vehicle_id: vehicle.addl_vehicle_id,
                      is_ev: vehicle.is_ev,
                      is_default: vehicle.is_default,
                      is_approved: vehicle.is_approved,
                      carousel_assignments: vehicle.carousel_assignments,
                      group: (vehicle.group && vehicle.group.name) || 'NA',
                    };
                  }),
                  page: res.page - 1,
                  totalCount: res.total,
                });
              })
              .catch(reject);
          }
        });

  const actions = [];
  if (hasPermission(Permissions.ADD_UPDATE_VEHICLES) ||
    ((isSameUser(user?.id) || isSameGroup(group?.id)) && hasPermission(Permissions.ADD_UPDATE_OWN_VEHICLE))) {
    actions.push(
      (rowData) => ({
        icon: 'edit',
        iconProps: { fontSize: 'small' },
        tooltip: 'Update Vehicle',
        onClick: () => {
          if (user?.id) {
            navigate(`/users/${user.id}/vehicles/edit/${rowData.id}`);
          } else if (group?.id) {
            navigate(`/groups/${group.id}/vehicles/edit/${rowData.id}`);
          } else {
            navigate('/vehicles/edit/' + rowData.id);
          }
        },
      })
    );
    actions.push(
      (rowData) => ({
        icon: 'delete',
        iconProps: { fontSize: 'small', style: { color: 'red' } },
        tooltip: 'Delete Vehicle',
        onClick: async () => {
          if (confirm('Are you sure you want to delete this vehicle?')) {
            try {
              await VehicleService.deleteVehicle(rowData.id);
              if (user?.id) {
                user = await UserService.getUser(user.id);
              } else if (group?.id) {
                group = await UserService.getGroupWithVehicles(group.id);
              }
            } catch (err) {
              alert(err);
            }
            tableRef.current && tableRef.current.onQueryChange();
          }
        },
      })
    );
    actions.push({
      icon: 'add',
      tooltip: 'Add Vehicle',
      position: 'toolbar',
      onClick: () => {
        if (user?.id) {
          navigate(`/users/${user.id}/vehicles/add`);
        } else if (group?.id) {
          navigate(`/groups/${group.id}/vehicles/add`);
        } else {
          navigate('/vehicles/add');
        }
      },
    })
  }

  return (
    <GridItem xs={12}>
      <Card>
        <MaterialTable
          title='Vehicles'
          tableRef={tableRef}
          columns={[
            { title: 'Approved?', render: rowData => rowData.is_approved ? <Check style={{ color: 'green' }}/> : <Close style={{ color: 'red' }}/> },
            { title: 'Type', field: 'type' },
            { title: 'Year', field: 'year' },
            { title: 'Make', field: 'make' },
            { title: 'Model', field: 'model' },
            { title: 'Color', field: 'color' },
            { title: 'EV?', render: rowData => rowData.is_ev ? <Check /> : '' },
            ...(addlVehicleId ? [{ title: addlVehicleId, field: 'addl_vehicle_id' }] : []),
            { title: 'Assignment', render: rowData => rowData?.carousel_assignments[0] ? (rowData.carousel_assignments[0].carousel.nickname + ' (' + rowData.carousel_assignments[0].carousel.location.name + ')') : '' },
          ]}
          data={fetchDataFn}
          actions={actions}
          components={{
            Toolbar: (props) => (
              <div style={{ alignItems: 'center', paddingRight: '0%' }}>
                <CardHeader color='primary' icon>
                  <CardIcon color='rose'>
                    <LocalShippingIcon />
                  </CardIcon>
                </CardHeader>
                <MTableToolbar {...props} />
              </div>
            ),
          }}
          options={{
            actionsColumnIndex: 1,
            debounceInterval: 500,
            filtering: false,
            sorting: true,
            pageSize: 5,
            search: false,
          }}
        />
      </Card>
    </GridItem>
  );
}

VehicleTable.propTypes = {
  user: PropTypes.object,
  group: PropTypes.object,
  refreshData: PropTypes.bool,
};

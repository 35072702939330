import React from 'react';
import MUIDataTable from 'mui-datatables';
import makeStyles from '@mui/styles/makeStyles';
import { Tooltip, IconButton, Typography } from '@mui/material';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardIcon from 'components/Card/CardIcon.js';
import styles from 'assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js';
import TransactionService from 'services/TransactionService';
import useAPI from 'utils/useAPI';
import moment from 'moment';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';

const useStyles = makeStyles((theme) => ({
  ...styles,
  chip: {
    margin: theme.spacing(0.5),
  },
}));

export default function Transactions() {
  const api = useAPI();
  const classes = useStyles();
  const [data, setData] = React.useState([]);
  const [params, setParams] = React.useState({
    pageSize: 10,
    page: 1,
    search: null,
    orderBy: null,
    orderDirection: null
  });


  const getDate = (dateTime) => {
    // return new Date(dateTime);
    return moment(dateTime).format('L');
  };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  const viewInvoice = (transaction) => {
    TransactionService.getInvoiceLink(transaction).then((result) => {
      openInNewTab(result);
    });
  };

  const refundTransaction = async (transaction) => {
    const isConfirmed = window.confirm('Are you sure you want to refund this transaction?');
    if (isConfirmed) {
      TransactionService.refundTransaction(transaction).then((result) => {
        console.log(result);
        fetchAllTransactions(params);
      });
    }
  };

  function transformTransaction(transaction) {
    let transformed_transaction = transaction;
    transformed_transaction = transformed_transaction.map((transaction) => {
      return {
        id: transaction.id,
        stripe_payment_intent_id: transaction.stripe_payment_intent_id,
        'parkingSession:user:name': transaction.parkingSession.user.name,
        'parkingSession:discount:value': transaction.parkingSession.discount
          ? transaction.parkingSession.discount.type === 'Percentage'
            ? transaction.parkingSession.discount.value + '%'
            : transaction.parkingSession.discount.value + '$'
          : 'NA',
        amount: `$${(transaction.price / 100).toFixed(2)}`,
        duration: transaction.parkingSession.duration ? transaction.parkingSession.duration : 'NA',
        'parkingSession:user_vehicle_pin': transaction.parkingSession.user_vehicle_pin,
        'parkingSession:start_time': getDate(transaction.parkingSession.start_time),
        'parkingSession:vehicle_name': transaction.parkingSession.vehicle_name,
        status: String(transaction.status).toUpperCase(),
        stripe_refund_id: transaction.stripe_refund_id,
        actions: (
          <>
            <div display='flex'>
              <Tooltip title='View Invoice' aria-label=''>
                <IconButton
                  onClick={() => {
                    viewInvoice(transaction.stripe_payment_intent_id);
                  }}
                  size='large'
                >
                  <ReceiptIcon style={{ color: '#0C3C78' }} />
                </IconButton>
              </Tooltip>
              <Tooltip
                title={transaction.stripe_refund_id ? 'Refunded' : 'Make Refund'}
                aria-label=''
              >
                <span>
                  <IconButton
                    onClick={async () => {
                      refundTransaction({
                        id: transaction.id,
                        payment_intent: transaction.stripe_payment_intent_id,
                        amount: transaction.amount,
                      });
                    }}
                    disabled={transaction.stripe_refund_id ? true : false}
                    size='large'
                  >
                    <AssignmentReturnIcon
                      color={transaction.stripe_refund_id ? 'secondary' : 'primary'}
                    />
                  </IconButton>
                </span>
              </Tooltip>
            </div>
          </>
        ),
      };
    });
    return transformed_transaction;
  }

  const fetchAllTransactions = (params) => {
    TransactionService.getTransactions(params.pageSize, params.page, params.search, params.orderBy, params.orderDirection)
      .then((res) => {
        setParams({
          ...params,
          page: res.page,
          count: res.total
        })
        let d = transformTransaction(res.results);
        setData(d);
      })
      .catch();
  };

  React.useEffect(() => {
    TransactionService.init(api);
    fetchAllTransactions(params);
  }, [api]);

  const columns = [
    {
      label: 'Actions',
      name: 'actions',
      options: {
       sort: false
      }
    },
    {
      label: 'PIN',
      name: 'parkingSession:user_vehicle_pin',
    },
    {
      label: 'Name',
      name: 'parkingSession:user:name',
    },
    {
      label: 'Amount',
      name: 'amount',
    },
    {
      label: 'Duration (Min)',
      name: 'duration',
    },
    {
      label: 'Date',
      name: 'parkingSession:start_time',
    },
    {
      label: 'Vehicle',
      name: 'parkingSession:vehicle_name',
    },

    {
      label: 'Status',
      name: 'status',
    },
    {
      label: 'Discount',
      name: 'parkingSession:discount:value',
    },
  ];

  const options = {
    serverSide: true,
    filter: false, // Hide filters
    print: false, // Hide print
    download: false, // Hide download
    selectableRowsHeader: false, // Hide checkbox in header
    selectableRowsHideCheckboxes: true, // Hide checkbox for every row
    onTableChange: (action, tableState) => {

      let sortCol = tableState.sortOrder?.name;
      const newParams = {
        pageSize: tableState.rowsPerPage,
        page: tableState.page + 1,
        search: tableState.searchText,
        orderBy: sortCol,
        orderDirection: sortCol ? tableState.sortOrder?.direction : null
      };
      if (["changePage", "search", "filterChange", "sort", "changeRowsPerPage"].includes(action)){
        setParams(newParams);
        fetchAllTransactions(newParams);
      }
    }
  };
  return (
    <GridContainer>
      <div className={classes.cardContentLeft}></div>
      <GridItem xs={12}>
        <Card>
          {data && (
            <MUIDataTable
              title={
                <div>
                  <CardIcon color='rose'>
                    <MonetizationOnIcon style={{ color: 'white' }} />{' '}
                  </CardIcon>
                  <Typography variant='h6'>Transactions</Typography>
                </div>
              }
              data={data}
              columns={columns}
              options={{...options, count: params.count}}
            />
          )}
        </Card>
      </GridItem>
    </GridContainer>
  );
}

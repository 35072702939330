import React, {useState, useEffect, useContext} from 'react';
import useAPI from '../../utils/useAPI';
import PropTypes from 'prop-types';
import { getConfig } from 'config';
import OneSignal from 'react-onesignal';
import { PinContext } from '../../components/PinLogin/PinContext';

const { oneSignalAppId, oneSignalSafariAppId, valetMode } = getConfig();

export const AuthContext = React.createContext({
  roles: [],
  permissions: [],
  privilegeLevel: 0,
  loading: true,
});

export const AuthProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [roles, setRoles] = useState([]);
  const [permissions, setPermissions] = useState(null);
  const [privilegeLevel, setPrivilegeLevel] = useState(0);
  const api = useAPI();
  const  { pin, notifyUnauthorized, notifyAuthorized } = useContext(PinContext);

  const isSuperuser = () => {
    return permissions && permissions.includes('*');
  }

  const hasPermission = (permissionName) => {
    return permissions && (isSuperuser() || permissions.includes(permissionName));
  };

  const hasAnyPermission = (permissionsArr) => {
    return permissions && (isSuperuser() || permissionsArr.some(p => permissions.includes(p)));
  };

  const isSameUser = (userId) => {
    return userId && Number(userId) === user?.id;
  }

  const isSameGroup = (groupId) => {
    return groupId && Number(groupId) === user?.group?.id;
  }

  const isRoleChange = (roleId) => {
    return !roles.some(r => r.id === roleId);
  }

  const getMaxPrivilegeLevel = (user) => {
    return user?.roles ? Math.max(...user.roles.map(obj => obj.privilege_level)) : 0;
  }

  const hasHigherPrivilegeLevel = (user) => {
    return privilegeLevel > getMaxPrivilegeLevel(user);
  }

  useEffect(() => {
    if ((!valetMode || pin) && !user) {
      fetchUser();
    }
  }, [pin]);

  useEffect(() => {
    if (!!user && !!permissions) {
      setToken();
      setLoading(false);
    }
  }, [user, permissions]);

  const refreshUser = async () => {
    fetchUser();
  }

  const fetchUser = async () => {
    try {
      let user = await api.get('/user');
      user = user.data;
      setUser(user);
      const roles = user.roles.map(role => {
        return { id: role.id, name: role.name, privilege_level: role.privilege_level }
      });
      setRoles(roles);
      setPrivilegeLevel(getMaxPrivilegeLevel(user));
      const allPermissions = user.roles.reduce((accum, role) => accum.concat(role.permissions), []);
      const uniquePermissions = [...new Set(allPermissions)];
      setPermissions(uniquePermissions.sort());
      notifyAuthorized();
    } catch (err) {
      if (err?.response?.status === 401) {
        notifyUnauthorized();
      } else {
        console.error(err);
      }
    }
  };

  const setToken = async () => {
    if (!valetMode && hasPermission('access_admin_app') && !!oneSignalAppId) {
      try {
        await OneSignal.init({
          appId: oneSignalAppId,
          ...(oneSignalSafariAppId &&
            {safari_web_id: oneSignalSafariAppId}),
          notifyButton: {
            enable: true,
            position: 'bottom-left',
          },
          autoResubscribe: true,
        });
        await OneSignal.login(`${user.id}`);
        // OneSignal changed tokens to subscription ids
        const subscriptionId = OneSignal.User.PushSubscription.id;
        if (subscriptionId) {
          console.log('OneSignal Subscription:', subscriptionId);
          api
            .post('/admin/set-token', {
              token: subscriptionId,
            })
            .catch((err) => console.error(err));
        }
      } catch (err) {
        if (!String(err).startsWith('OneSignal is already initialized')) {
          console.log('Error initializing OneSignal', err);
        }
      }
    }
  };

  return (
    <AuthContext.Provider value={{ roles, privilegeLevel, permissions, hasPermission, hasAnyPermission, isSameUser, isSameGroup, isSuperuser, isRoleChange, hasHigherPrivilegeLevel, loading, refreshUser }}>
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

import React from 'react';
import MUIDataTable from 'mui-datatables';
import { toUSNationalPhoneNumber } from '../../utils/Utils';
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardIcon from 'components/Card/CardIcon.js';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import modalStyle from 'assets/jss/material-dashboard-pro-react/modalStyle.js';
// apis
import SubscriptionBookingService from 'services/SubscriptionBookingService';
import useAPI from 'utils/useAPI';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Close from '@mui/icons-material/Close';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Slide from '@mui/material/Slide';

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import SmsIcon from '@mui/icons-material/Sms';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
const useStyles = makeStyles(modalStyle);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />;
});

export default function SubscriptionBooking() {
  const api = useAPI();
  const navigate = useNavigate();
  let path = '/user-subscription-booking/';
  const [modal, setModal] = React.useState(false);
  const [bookingId, setBookingId] = React.useState(0);
  const [message, setMessage] = React.useState('');
  const [data, setData] = React.useState(null);

  const classes = useStyles();

  const changeStatus = async (data) => {
    await SubscriptionBookingService.updateCurrentSubscriptionBooking(data);
  };

  const fetchSubscriptionBooking = () => {
    SubscriptionBookingService.getSubscriptionBookings().then((res) => {
      let d = transform(res);
      setData(d);
    });
  };
  React.useEffect(() => {
    SubscriptionBookingService.init(api);
    fetchSubscriptionBooking();
  }, [api]);

  const filterString = (value) => {
    return value ? value : 'NA';
  };

  const refundSubscription = async (item) => {
    if (item.refunded) {
      return;
    }
    SubscriptionBookingService.refund(item).then((result) => {
      console.log(result);
      fetchSubscriptionBooking();
    });
  };

  const handleMessage = (event) => {
    // console.log(event.target.value);
    setMessage(event.target.value);
  };

  const sendSMS = () => {
    console.log('msg', bookingId, message);
    SubscriptionBookingService.sendSMS(bookingId, message).then((res) => {
      console.log('!23', res);
    });
  };

  function transform(session) {
    let transformed_subscriptions = session;
    transformed_subscriptions = transformed_subscriptions.map((ses) => {
      return {
        id: ses.id,
        name: filterString(ses.user.name),
        phone: <>{toUSNationalPhoneNumber(ses.user.phone) + ' '}</>,
        vehicle_name: ses.vehicle_name,
        vehicle_type: ses.vehicle_type,
        // ticketUrl: ses.ticketUrl,
        vehicle_status: <>{String(ses.vehicle_status).toUpperCase() + ' '}</>,
        actions: (
          <>
            <div display='flex'>
              <Tooltip title='Send SMS' aria-label=''>
                <IconButton
                  onClick={() => {
                    setModal(true);
                    setBookingId(ses.id);
                  }}
                  size='large'
                >
                  <SmsIcon style={{ color: '#0C3C78' }} />
                </IconButton>
              </Tooltip>
              <Tooltip title='Booking History' aria-label=''>
                <IconButton
                  onClick={() => {
                    navigate(path + ses.id + '/navigate');
                  }}
                  size='large'
                >
                  <VisibilityIcon style={{ color: '#8FD6E1' }} />
                </IconButton>
              </Tooltip>
              <Tooltip title={ses.refunded ? 'Refunded' : 'Make Refund'} aria-label=''>
                <IconButton
                  onClick={() => {
                    refundSubscription(ses);
                  }}
                  size='large'
                >
                  <AssignmentReturnIcon color={ses.refunded ? 'secondary' : 'primary'} />
                </IconButton>
              </Tooltip>
              {ses.payment_status === 'paid' &&
                ((ses.vehicle_status === 'unparked' && (
                  <Tooltip title='Park' aria-label=''>
                    <IconButton
                      onClick={() => {
                        changeStatus({
                          id: ses.id,
                          vehicle_status: 'parked',
                        }).then(() => {
                          fetchSubscriptionBooking();
                        });
                      }}
                      size='large'
                    >
                      <ArrowUpwardIcon color='primary' />
                    </IconButton>
                  </Tooltip>
                )) ||
                  (ses.vehicle_status === 'parked' && (
                    <Tooltip title='Retrieve' aria-label=''>
                      <IconButton
                        onClick={() => {
                          changeStatus({
                            id: ses.id,
                            vehicle_status: 'retrieved',
                          }).then(() => {
                            fetchSubscriptionBooking();
                          });
                        }}
                        size='large'
                      >
                        <ArrowDownwardIcon style={{ color: 'red' }} />
                      </IconButton>
                    </Tooltip>
                  )))}
            </div>
          </>
        ),
      };
    });
    return transformed_subscriptions;
  }

  const columns = [
    {
      label: 'Actions',
      name: 'actions',
      options: {
        filter: true,
        setCellProps: () => ({
          style: {
            whiteSpace: 'nowrap',
            position: 'sticky',
            left: '0',
            background: 'white',
            zIndex: 100,
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: 'nowrap',
            position: 'sticky',
            left: 0,
            background: 'white',
            zIndex: 101,
          },
        }),
      },
    },
    {
      label: 'ID',
      name: 'id',
    },
    {
      label: 'Name',
      name: 'name',
    },
    {
      label: 'Phone',
      name: 'phone',
      options: {
        sortCompare: (order) => {
          return (obj1, obj2) => {
            let val1 = obj1.data.props.children.replace(/[^\d]/g, '');
            let val2 = obj2.data.props.children.replace(/[^\d]/g, '');
            return (val1 - val2) * (order === 'asc' ? 1 : -1);
          };
        },
      },
    },
    {
      label: 'Vehicle',
      name: 'vehicle_name',
    },
    {
      label: 'Vehicle Type',
      name: 'vehicle_type',
    },
    {
      label: 'Vehicle Status',
      name: 'vehicle_status',
      options: {
        sortCompare: (order) => {
          return (obj1, obj2) => {
            let val1 = obj1.data.props.children;
            let val2 = obj2.data.props.children;
            return (val1.length < val2.length ? -1 : 1) * (order === 'asc' ? 1 : -1);
          };
        },
      },
    },
  ];
  const options = {
    filter: false, // Hide filters
    print: false, // Hide print
    download: false, // Hide download
    selectableRowsHeader: false, // Hide checkbox in header
    selectableRowsHideCheckboxes: true, // Hide checkbox for every row
  };
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          {data && (
            <MUIDataTable
              title={
                <div>
                  <CardIcon color='rose'>
                    <CollectionsBookmarkIcon style={{ color: 'white' }} />{' '}
                  </CardIcon>
                  <Typography variant='h6'>SUBSCRIPTION BOOKINGS</Typography>
                </div>
              }
              data={data}
              columns={columns}
              options={options}
            />
          )}
          <Dialog
            classes={{
              root: classes.center,
              paper: classes.modal,
            }}
            open={modal}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => {
              setModal(false);
              setBookingId(0);
            }}
            aria-labelledby='modal-slide-title'
            aria-describedby='modal-slide-description'
          >
            <DialogTitle id='classic-modal-slide-title' className={classes.modalHeader}>
              <IconButton
                className={classes.modalCloseButton}
                key='close'
                aria-label='Close'
                color='inherit'
                onClick={() => {
                  setModal(false);
                  setBookingId(0);
                }}
                size='large'
              >
                <Close className={classes.modalClose} />
              </IconButton>
              <div className={classes.modalTitle}>Send SMS</div>
            </DialogTitle>
            <DialogContent id='modal-slide-description' className={classes.modalBody}>
              <TextField
                id='outlined-multiline-static'
                label='Message'
                multiline
                rows={4}
                defaultValue=''
                fullWidth
                onChange={handleMessage}
              />
            </DialogContent>
            <DialogActions className={classes.modalFooter + ' ' + classes.modalFooterCenter}>
              <Button
                onClick={() => {
                  setModal(false);
                  setMessage('');
                  setBookingId(0);
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  setModal(false);
                  console.log(message, bookingId);
                  sendSMS();
                  setMessage('');
                }}
                color='primary'
              >
                Send
              </Button>
            </DialogActions>
          </Dialog>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

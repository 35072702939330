import React, { useState, useRef, useEffect, useContext } from 'react';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardBody from 'components/Card/CardBody.js';
import UserService from 'services/UserService';
import styles from 'assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js';
import { Checkbox, FormControlLabel } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import GroupIcon from '@mui/icons-material/Group';
import useAPI from 'utils/useAPI';
import { FormControl, TextField } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { PatternFormat } from 'react-number-format';
import Loading from 'components/Loading';
import {AuthContext} from '../Auth/AuthContext';
import cloneDeep from 'lodash/cloneDeep';
import { toUSNationalPhoneNumber, States } from '../../utils/Utils';
import {Permissions} from '../Auth/Permissions';
import FormattedInput from "../../components/Pattern/FormattedInput"
import { getConfig } from 'config';
import { PinContext } from '../../components/PinLogin/PinContext';

const useStyles = makeStyles(styles);

function UserForm({ id = null }) {
  const navigate = useNavigate();
  const api = useAPI();
  const  { pinMinNumDigits, valetMode } = getConfig();
  const  { logout } = useContext(PinContext);
  const classes = useStyles();
  const [user, setUser] = useState({
    name: '',
    address: '',
    city: '',
    state: '',
    zipcode: '',
    email: '',
    phone: '',
    pin: '',
  });
  const [roles, setRoles] = useState([]);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(true);
  const { isSameUser, isSuperuser, privilegeLevel, isRoleChange, hasHigherPrivilegeLevel, refreshUser, hasPermission, hasAnyPermission } = useContext(AuthContext);

  const action = id ? 'Update User' : 'Add User';

  const verifyLength = (value, length) => {
    return value.length >= length;
  };

  useEffect(() => {
    UserService.init(api);
    if (id) {
      fetchUser(id);
    }
    fetchRoles();
  }, [api]);

  const origUser = useRef();
  const fetchUser = async (id) => {
    const res = await UserService.getUser(id);
    res.phone = toUSNationalPhoneNumber(res?.phone);
    origUser.current = cloneDeep(res);
    setUser(res);
  };

  const fetchRoles = async () => {
    const res = await UserService.getRoles();
    setRoles(res);
  };

  useEffect(() => {
    if (roles.length > 0 && (!id || user?.id)) {
      setLoading(false);
    }
  }, [user, roles]);

  const REQUIRED_FIELDS = ['name', 'email'];
  const handleChange = (prop, val) => {
    if (prop === 'role') {
      setUser({...user, roles: [{id: val}]})
      setErrors({...errors, roles: 'success'});
    } else {
      setUser({ ...user, [prop]: val });
      if (REQUIRED_FIELDS.includes(prop) && !verifyLength(val, 1)) {
        setErrors({ ...errors, [prop]: 'error' });
      } else {
        setErrors({ ...errors, [prop]: 'success' });
      }
    }
  };

  const pinChanged = () => {
    const pin = user.pin;
    return (origUser.current ? origUser.current.pin !== pin : pin !== '');
  }

  const handleSave = async () => {
    const newErrors = {};

    ['name', 'email'].forEach((field) => {
      if (!user[field] || user[field] === '') {
        newErrors[field] = 'error';
      }
    });
    if (!user.roles || !user.roles[0]) {
      newErrors.role = 'error';
    }
    if (pinChanged() && user.pin.length > 0 && user.pin.length < pinMinNumDigits) {
      newErrors.pin = 'error';
    }
    setErrors({ ...newErrors });
    if (Object.keys(newErrors).length > 0) {
      return;
    }

    if (id && isSuperuser() && isSameUser(id) && isRoleChange(user?.roles[0]?.id)) {
      const proceed = confirm("Hey, you're a superuser. You can do anything. Are you sure you want to give up your super powers?");
      if (!proceed) return;
    }

    if (pinChanged() && valetMode && isSameUser(id)) {
      const proceed = confirm('Changing your PIN will require logging in again. Proceed?');
      if (!proceed) return;
    }

    const data = {
      ...(id && { id }),
      name: user.name,
      address: user.address,
      city: user.city,
      state: user.state,
      zipcode: user.zipcode,
      phone: user.phone,
      email: user.email,
      ...(pinChanged() && {pin: user.pin}),
      roleId: user?.roles[0]?.id,
      is_free_parking: user.is_free_parking ? 1 : 0,
      is_allow_geoparking: user.is_allow_geoparking ? 1 : 0,
      is_new: user.is_new,
      is_agree: user.is_agree,
      is_admin_sms_notifications: user.is_admin_sms_notifications,
      is_queuing_notifications: user.is_queuing_notifications,
    };
    try {
      await UserService.updateUser(data);
      if (valetMode && pinChanged() && isSameUser(id)) {
        logout();
        return;
      }
      if (id && isSameUser(id)) {
        await refreshUser();
      }
      navigate('/admin/users');
    } catch (err) {
      if (err.includes('ER_DUP_ENTRY')) {
        const duplicateEmail = err.includes('users.email');
        const duplicatePin = err.includes('users.unique_pin');
        if (duplicateEmail) {
          alert(`Email ${data.email} is already in use.`);
          setErrors((errs) => ({
            ...errs,
            email: 'error',
          }));
        } else if (duplicatePin) {
          // Revert to original pin
          if (origUser.current) {
            if (origUser.current.pin) {
              setUser({ ...user, pin: origUser.current.pin });
              alert('Sorry, that PIN is already taken. PIN has been reset to original value.');
            } else {
              setUser({ ...user, pin: '' });
              alert('Sorry, that PIN is already taken.');
            }
          } else {
            alert('Sorry, that PIN is already taken.');
          }
        } else {
          console.error(err);
          alert('An unexpected error occurred');
        }
      } else {
        console.error(err);
        alert('An unexpected error occurred');
      }
    }
  };

  const handleCancel = async () => {
    navigate('/admin/users');
  };

  if (loading) {
    return <Loading />;
  }

  let allowPersonalDataEditing =
      isSuperuser()
      || (isSameUser(id) && hasAnyPermission([Permissions.UPDATE_OWN_USER, Permissions.ADD_UPDATE_USERS]))
      || (hasPermission(Permissions.ADD_UPDATE_USERS) && (!origUser || hasHigherPrivilegeLevel(origUser.current)));
  const disablePersonalDataEditing = !allowPersonalDataEditing;
  const allowRoleEditing =
      isSuperuser()
      || (!isSameUser(id) && hasPermission(Permissions.ADD_UPDATE_USERS) && (!origUser || hasHigherPrivilegeLevel(origUser.current)));
  const disableRoleEditing = !allowRoleEditing;
  const disableFreeParkingSelection = disableRoleEditing;
  const disableAnyEditing = disablePersonalDataEditing && disableRoleEditing && disableFreeParkingSelection;

  return (
    <GridItem xs={12} sm={12} md={12}>
      <Card>
        <CardHeader color='rose' icon>
          <CardIcon color='rose'>
            <GroupIcon />
          </CardIcon>
          <div className={classes.cardIconTitle}>{action}</div>
        </CardHeader>
        <CardBody>
          <form>
            <TextField
              error={errors.name === 'error'}
              margin='dense'
              id='name'
              label='Name *'
              type='text'
              fullWidth
              value={user.name}
              disabled={disablePersonalDataEditing}
              onChange={(event) => {
                handleChange('name', event.target.value);
              }}
            />
            <TextField
              margin='dense'
              id='email'
              label='Email *'
              type='text'
              fullWidth
              value={user.email}
              disabled={disablePersonalDataEditing || isSameUser(id)}
              onChange={(event) => {
                handleChange('email', event.target.value);
              }}
              error={errors.email === 'error'}
            />
            <PatternFormat
              id='phone'
              customInput={TextField}
              label='Phone'
              fullWidth={true}
              margin={'dense'}
              type={'text'}
              format={'(###) ###-####'}
              mask={' '}
              value={user.phone}
              disabled={disablePersonalDataEditing}
              onValueChange={(values) => {
                handleChange('phone', values.formattedValue);
              }}
              error={errors.phone === 'error'}
            />
            <FormControl fullWidth margin='dense' className={classes.formControl}>
              <InputLabel id='user_role'>User Role *</InputLabel>
              <Select
                labelId='user_role'
                id='user_role'
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                label='User Role *'
                value={loading ? '' : (user?.roles && user.roles.length > 0) ? user.roles[0].id : ''}
                disabled={disableRoleEditing}
                onChange={(e) => {
                  handleChange('role', e.target.value);
                }}
                error={errors.role === 'error'}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  Choose User Role *
                </MenuItem>

                {roles.map((role) => (
                  <MenuItem
                    value={role.id}
                    key={role.id}
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    disabled={!isSuperuser() && role.privilege_level >= privilegeLevel}
                  >
                    {`${role.name}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              margin='dense'
              id='pin'
              label='PIN'
              value={user.pin || ''}
              disabled={disablePersonalDataEditing}
              onChange={(event) => {
                const value = event.target.value;
                if (/^[0-9]*$/.test(value)) {
                  handleChange('pin', value);
                }
              }}
              type='password'
              error={errors.pin === 'error'}
              helperText={errors.pin === 'error' ? `PIN must be at least ${pinMinNumDigits} digits` : ''}
            />
            <TextField
              margin='dense'
              id='address'
              label='Address'
              value={user.address || ''}
              disabled={disablePersonalDataEditing}
              onChange={(event) => {
                handleChange('address', event.target.value);
              }}
              type='text'
              fullWidth
              multiline
              rows={2}
              error={errors.address === 'error'}
            />
            <TextField
              margin='dense'
              id='city'
              label='City'
              value={user.city || ''}
              disabled={disablePersonalDataEditing}
              onChange={(event) => {
                handleChange('city', event.target.value);
              }}
              type='text'
              fullWidth
              error={errors.city === 'error'}
            />
            <FormControl fullWidth margin='dense' className={classes.formControl}>
              <InputLabel id='state-id'>State</InputLabel>
              <Select
                label='State'
                value={user.state || ''}
                required
                disabled={disablePersonalDataEditing}
                onChange={(event) => {
                  handleChange('state', event.target.value);
                }}>
                <MenuItem
                  disabled
                  value=''
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  Choose State
                </MenuItem>

                {States &&
                  States.map((state) => (
                    <MenuItem
                      value={`${state}`}
                      key={`${state}`}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                    >
                      {`${state}`}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            <FormattedInput
              name='zipcode'
              format='#####'
              mask=' '
              label='Zip Code'
              value={user.zipcode || ''}
              disabled={disablePersonalDataEditing}
              onChange={(event) => {
                handleChange('zipcode', event.target.value);
              }}
              error={errors.zipcode === 'error'}
            />

            <FormControl fullWidth>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!user.is_free_parking}
                    disabled={disableFreeParkingSelection}
                    onChange={(event) => {
                      handleChange('is_free_parking', event.target.checked);
                    }}
                  />
                }
                label='Free Parking'
              />
            </FormControl>
            <FormControl fullWidth>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!user.is_allow_geoparking}
                    disabled={disablePersonalDataEditing}
                    onChange={(event) => {
                      handleChange('is_allow_geoparking', event.target.checked);
                    }}
                  />
                }
                label='Allow Geo-Parking'
              />
            </FormControl>
            <FormControl fullWidth>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!user.is_admin_sms_notifications}
                    disabled={disablePersonalDataEditing}
                    onChange={(event) => {
                      handleChange('is_admin_sms_notifications', event.target.checked);
                    }}
                  />
                }
                label='Receive Admin SMS Notifications'
              />
            </FormControl>
            <FormControl fullWidth>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!user.is_queuing_notifications}
                    disabled={disablePersonalDataEditing}
                    onChange={(event) => {
                      handleChange('is_queuing_notifications', event.target.checked);
                    }}
                  />
                }
                label='Receive Park/Retrieve Notifications'
              />
            </FormControl>
            <div className={classes.formCategory}>
              <small>*</small> Required fields
            </div>

            <Button color='danger' onClick={handleCancel} className={classes.registerButton}>
              Cancel
            </Button>
            <Button color='success' onClick={handleSave} className={classes.registerButton} disabled={disableAnyEditing}>
              {action}
            </Button>

          </form>
        </CardBody>
      </Card>
    </GridItem>
  );
}

UserForm.propTypes = {
  id: PropTypes.number,
};

UserForm.defaultProps = {
  id: null,
};

export default UserForm;

import React, { useState, useEffect, useContext } from 'react';
import styles from './PinLogin.module.css';
import { PinContext } from './PinContext';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

const PinLogin = () => {
    const [pin, setPin] = useState('');
    const { setPin: updatePinInContext, isInvalidPin, idleReset } = useContext(PinContext);

    const handleButtonClick = (value) => {
        setPin(pin + value);
    };

    const handleDelete = () => {
        setPin(pin.slice(0, -1));
    };

    const handleSubmit = () => {
        updatePinInContext(pin);
    };

    useEffect(() => {
        if (isInvalidPin) {
            setPin('');
        }
    }, [isInvalidPin])

    useEffect(() => {
        setPin('');
    }, [idleReset]);

    return (
        <div className={styles.mainContainer}>
            <div className={styles.pinContainer}>
                <input
                    className={styles.pinInput}
                    type="password"
                    value={pin}
                    onChange={(e) => setPin(e.target.value)}
                    maxLength="6"
                />
                <div className={styles.keypad}>
                    {[...Array(9)].map((_, index) => (
                        <button
                            key={index + 1}
                            className={styles.key}
                            onClick={() => handleButtonClick((index + 1).toString())}
                        >
                            {index + 1}
                        </button>
                    ))}
                    <button className={styles.key} onClick={handleSubmit}>
                        &#x2713;
                    </button>
                    <button className={styles.key} onClick={() => handleButtonClick('0')}>
                        0
                    </button>
                    <button className={styles.key} onClick={handleDelete}>
                        &#x232b;
                    </button>
                </div>
            </div>

            <Dialog open={isInvalidPin}>
                <DialogContent>
                    <DialogContentText style={{fontSize: 20}}>
                        Invalid PIN
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default PinLogin;

import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import QRReaderService from 'services/QRReaderService';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardBody from 'components/Card/CardBody.js';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import {useNavigate} from 'react-router-dom';
import useAPI from 'utils/useAPI';
import styles from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
import LocationService from 'services/LocationService';
import CarouselService from 'services/CarouselService';
import PropTypes from 'prop-types';

const useStyles = makeStyles(styles);

function QRReaderForm({ id = null }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const api = useAPI();
  const [locationList, setLocationList] = React.useState([]);
  const [carouselList, setCarouselList] = React.useState([]);
  const [formData, setFormData] = React.useState({
    name: '',
    location_id: '',
    carousel_id: '',
    device_key: '',
  });
  const [formErrors, setFormErrors] = React.useState({});

  const fetchQRReader = async () => {
    const res = await QRReaderService.getQRReader(id);
    if (!res.carousel_id) {
      res.carousel_id = '';
    }
    await fetchCarouselList(res.location_id);
    setFormData(res);
  };

  React.useEffect(() => {
    LocationService.init(api);
    CarouselService.init(api);
    QRReaderService.init(api);
    fetchLocationList().then(() => {
      if (id) fetchQRReader();
    });
  }, [api]);

  const fetchLocationList = async () => {
    const res = await LocationService.getLocations();
    setLocationList(
      res.map((item) => {
        return {
          id: item.id,
          name: item.name + ' - ' + item.zipcode,
          value: item.id,
        };
      }),
    );
    if (res.length === 1) {
      await setLocationId(res[0].id);
    }
  };

  const fetchCarouselList = async (location_id) => {
    await CarouselService.getLocationCarousels(location_id).then((res) => {
      setCarouselList(
        res.map((item) => {
          return {
            id: item.id,
            name: item.nickname,
            value: item.id,
          };
        }),
      );
    });
  };

  function validate() {
    const errors = {};
    if (!formData.name || formData.name === '') {
      errors.name = 'Name is required';
    }
    if (!formData.location_id || formData.location_id === '') {
      errors.location_id = 'Location is required';
    }
    return errors;
  }

  const handleChange = (prop, val) => {
    setFormData({ ...formData, [prop]: val === 'clear' ? '' : val });
  };

  const setLocationId = async (location_id) => {
    setFormData({ ...formData, location_id: Number(location_id) });
    await fetchCarouselList(location_id);
  };

  const handleSave = async () => {
    let errors = validate();
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      if (id) {
        await QRReaderService.updateQRReader(formData);
      } else {
        await QRReaderService.addQRReader(formData);
      }
      navigate(`/admin/qr-reader`);
    }
  };

  const cancelRoute = () => {
    navigate(`/admin/qr-reader`);
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color='rose' icon>
            <CardIcon color='rose'>
              <LocalAtmIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>{id ? 'Edit' : 'Add'} QR Reader</h4>
          </CardHeader>
          <CardBody>
            <form>
              <TextField
                type='text'
                id='name'
                label='Name'
                value={formData.name}
                error={!!formErrors.name}
                helperText={formErrors.name}
                autoFocus
                margin='dense'
                fullWidth
                onChange={(event) => {
                  handleChange('name', event.target.value);
                }}
              />
              <FormControl fullWidth margin='dense' className={classes.formControl} error={!!formErrors.location_id}>
                <InputLabel>Location</InputLabel>
                <Select
                  id='location_id'
                  label='Location'
                  value={formData.location_id}
                  fullWidth
                  onChange={(e) => {
                    setLocationId(e.target.value);
                  }}
                >
                  {locationList.map((location) => (
                    <MenuItem
                      value={`${location.id}`}
                      key={`${location.id}`}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                    >
                      {`${location.name}`}
                    </MenuItem>
                  ))}
                </Select>
                {formErrors.location_id && <FormHelperText>{formErrors.location_id}</FormHelperText>}
              </FormControl>
              <FormControl fullWidth margin='dense' className={classes.selectFormControl}>
                <InputLabel>Carousel</InputLabel>
                <Select
                  label='Carousel'
                  id='carousel_id'
                  fullWidth
                  value={formData.carousel_id}
                  onChange={(e) => {
                    handleChange('carousel_id', e.target.value);
                  }}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    Choose Carousel
                  </MenuItem>
                  <MenuItem key='clear' value='clear'>Clear selection</MenuItem>
                  {carouselList.map((carousel) => (
                    <MenuItem
                      value={carousel.id}
                      key={carousel.id}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                    >
                      {`${carousel.name}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                type='text'
                id='device_key'
                label='Device Key'
                value={formData.device_key}
                margin='dense'
                fullWidth
                onChange={(event) => {
                  handleChange('device_key', event.target.value);
                }}
              />
              <Button
                color='success'
                onClick={handleSave}
              >
                Submit
              </Button>
              <Button color='danger' onClick={cancelRoute}>
                Cancel
              </Button>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

QRReaderForm.propTypes = {
  id: PropTypes.string,
};

QRReaderForm.defaultProps = {
  id: null,
};

export default QRReaderForm;

import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import UserService from 'services/UserService';
import DiscountService from 'services/DiscountService';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardBody from 'components/Card/CardBody.js';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import useAPI from 'utils/useAPI';
import ParkingSessionService from 'services/ParkingSessionService';
import styles from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
import LocationService from 'services/LocationService';
import PropTypes from 'prop-types';
import moment from 'moment';
import { DateTimePicker } from '@mui/x-date-pickers';

const useStyles = makeStyles(styles);
const types = [{ name: 'Amount' }, { name: 'Percentage' }, { name: 'Hours' }];

const today = moment().startOf('day').toDate();
const afterTwoDays = moment().add(2, 'days').endOf('day').toDate();
function DiscountForm({ id = null }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const api = useAPI();
  const [locationList, setLocationList] = React.useState([]);
  const [values, setValues] = React.useState({
    code: '',
    value: '',
    type: '',
    user: [],
    location: [],
    applies_to: [],
    to_all_user: true,
    to_all_locations: true,
    start_time: moment(today),
    expire_time: moment(afterTwoDays),
    new_signup: false,
    apply_once_per_customer: false,
  });
  const [errors, setErrors] = React.useState({
    code: '',
    value: '',
    type: '',
    user: '',
    start_time: '',
    expire_time: '',
    applies_to: '',
    location: '',
  });

  const fetchDiscount = async () => {
    await DiscountService.getDiscount(id).then((res) => {
      res.applies_to = res.apply_to_locations === 1 ? ['location'] : [];
      // res.applies_to = [];
      let locations = [];
      res.location.forEach((item) => {
        locations.push(item.id);
      });
      res.location = locations;
      res.start_time = moment(res.start_time);
      res.expire_time = moment(res.expire_time);
      setValues(res);
    });
  };

  React.useEffect(() => {
    ParkingSessionService.init(api);
    UserService.init(api);
    LocationService.init(api);
    DiscountService.init(api);
    fetchLocationList().then(() => {
      // Fetching discount after fetching location so that first default values are set, then set values fetched by discount
      if (id) fetchDiscount();
    });
  }, [api]);

  const fetchLocationList = async () => {
    await LocationService.getLocations().then((res) => {
      setLocationList(
        res.map((item) => {
          return {
            id: item.id,
            name: item.name + ' - ' + item.zipcode,
            value: item.id,
          };
        }),
      );
      if (res.length === 1) {
        const defaultLocation = [res[0].id];
        setValues({ ...values, location: defaultLocation });
      }
    });
  };

  const handleCheckbox = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.checked });
  };

  const handleChange = (prop, val) => {
    setValues({ ...values, [prop]: val });
    if (verifyLength(val, 1)) {
      setErrors({ ...errors, [prop]: 'success' });
    } else {
      setErrors({ ...errors, [prop]: 'error' });
    }
  };

  const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };

  const handleCode = async () => {
    let val = Math.random().toString(36).slice(-8).toUpperCase();
    handleChange('code', val);
  };
  const handleSave = async () => {
    let error = 0;
    let newErrors = errors;
    [
      'code',
      'type',
      // "start_time",
      // "expire_time",
      'applies_to',
      'location',
    ].forEach((field) => {
      if (field === 'location') {
        if (
          values.applies_to.find((element) => element === 'location') &&
          !values.to_all_locations &&
          !values['location'].length
        ) {
          newErrors['location'] = 'error';
          error = 1;
        }
      } else if (values[field] === '' || !values[field].length) {
        newErrors[field] = 'error';
        error = 1;
      }
    });
    setErrors({ ...newErrors });
    console.log('newErrors', newErrors, values);
    if (error) return;

    if (values.start_time && values.expire_time && values.expire_time <= values.start_time) {
      newErrors['expire_time'] = "error";
    }

    if (values.start_time) {
      values.start_time = moment(values.start_time).format('YYYY-MM-DD HH:mm:ss');
    }

    if (values.expire_time) {
      values.expire_time = moment(values.expire_time).format('YYYY-MM-DD HH:mm:ss');
    }

    if (id) await DiscountService.updateDiscount(values);
    else await DiscountService.addDiscount(values);

    let path = `/admin/discount`;
    navigate(path);
  };
  const cancelRoute = () => {
    let path = `/admin/discount`;
    navigate(path);
  };
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color='rose' icon>
            <CardIcon color='rose'>
              <LocalAtmIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Add Discount</h4>
          </CardHeader>
          <CardBody>
            <form>
              <GridItem xs={12} sm={12}>
                <GridContainer>
                  <GridItem xs={8} sm={8} md={8}>
                    <TextField
                      error={errors.code === 'error'}
                      autoFocus
                      margin='dense'
                      id='promo_code'
                      label='Promo Code'
                      type='text'
                      fullWidth
                      value={values.code}
                      onChange={(event) => {
                        handleChange('code', event.target.value);
                      }}
                    />
                  </GridItem>
                  <GridItem xs={4} sm={4} md={4}>
                    <Button color='success' onClick={handleCode}>
                      Auto Generate Code
                    </Button>
                  </GridItem>
                </GridContainer>
              </GridItem>
              <FormControl fullWidth margin='dense' className={classes.formControl}>
                <InputLabel>Type *</InputLabel>
                <Select
                  id='type'
                  fullWidth
                  label='Type *'
                  value={values.type}
                  onChange={(e) => {
                    handleChange('type', e.target.value);
                  }}
                  error={errors.type === 'error'}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    Choose type *
                  </MenuItem>

                  {types.map((type) => (
                    <MenuItem
                      value={`${type.name}`}
                      key={`${type.name}`}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                    >
                      {`${type.name}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                error={errors.value === 'error'}
                autoFocus
                margin='dense'
                id='value'
                label='Value'
                type='text'
                fullWidth
                value={values.value}
                onChange={(event) => {
                  handleChange('value', event.target.value);
                }}
              />
              <FormControl fullWidth margin='dense' className={classes.selectFormControl}>
                <InputLabel htmlFor='multiple-select' className={classes.selectLabel}>
                  Applies To
                </InputLabel>
                <Select
                  multiple
                  label='Applies To'
                  id='applies_to'
                  fullWidth
                  value={values.applies_to}
                  onChange={(e) => {
                    handleChange('applies_to', e.target.value);
                  }}
                  error={errors.type === 'error'}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    Choose Applies to
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value='location'
                  >
                    Location
                  </MenuItem>
                </Select>
              </FormControl>
              {values.applies_to.find((element) => element === 'location') && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!!values.to_all_locations}
                      onChange={handleCheckbox('to_all_locations')}
                    />
                  }
                  label='Applies to All Locations'
                />
              )}
              <br />
              <br />
              {!values.to_all_locations && (
                <FormControl fullWidth className={classes.selectFormControl} margin='dense'>
                  <InputLabel>Choose Specific Location to Apply PromoCode</InputLabel>
                  <Select
                    multiple
                    id='location'
                    fullWidth
                    label='Choose Specific Location to Apply PromoCode'
                    value={values.location}
                    onChange={(e) => {
                      handleChange('location', e.target.value);
                    }}
                    error={errors.location === 'error'}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                    >
                      Choose Specific Location to Apply PromoCode
                    </MenuItem>
                    {locationList &&
                      locationList.map((location) => (
                        <MenuItem
                          value={`${location.id}`}
                          key={`${location.id}`}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                        >
                          {`${location.name}`}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}
              {values.start_time &&
                <DateTimePicker
                  label='Start Time'
                  value={values.start_time}
                  inputFormat='MM/dd/yyyy hh:mm a'
                  onChange={(e) => {
                    setValues({ ...values, start_time: e });
                    setErrors({ ...errors, start_time: 'success' });
                  }}
                  renderInput={(params) => <TextField {...params} sx={{ mt: 1, mr: 1 }} />}
                />
              }

              {values.expire_time &&
                <DateTimePicker
                  label='End Time'
                  value={values.expire_time}
                  minDate={values.start_time}
                  inputFormat='MM/dd/yyyy hh:mm a'
                  onChange={(e) => {
                    setValues({ ...values, expire_time: e });
                    setErrors({ ...errors, expire_time: 'success' });
                  }}
                  renderInput={(params) => <TextField {...params} sx={{ mt: 1, mr: 1 }} />}
                />
              }

              <div className={classes.checkboxAndRadio}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!!values.new_signup}
                      onChange={handleCheckbox('new_signup')}
                    />
                  }
                  label='Apply only to new Signup'
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!!values.apply_once_per_customer}
                      onChange={handleCheckbox('apply_once_per_customer')}
                    />
                  }
                  label='Apply once per customer'
                />
              </div>
              <Button color="success" onClick={handleSave} disabled={values.expire_time <= values.start_time}>
                Submit
              </Button>
              <Button color='danger' onClick={cancelRoute}>
                Cancel
              </Button>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

DiscountForm.propTypes = {
  id: PropTypes.string,
};

DiscountForm.defaultProps = {
  id: null,
};

export default DiscountForm;

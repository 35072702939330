import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

// @mui/icons-material
import CreateIcon from '@mui/icons-material/Create';

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardBody from 'components/Card/CardBody.js';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import {Checkbox, FormControl, FormControlLabel, TextField} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import useAPI from 'utils/useAPI';
import CarouselService from 'services/CarouselService';
import LocationService from 'services/LocationService';
import styles from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
import PropTypes from "prop-types"

const useStyles = makeStyles(styles);

export default function CarouselForm({ id }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const api = useAPI();
  const [carousel, setCarousel] = React.useState('');
  const [locationList, setLocationList] = React.useState([]);
  const [location, setLocation] = React.useState('');
  const [ipAddress, setIpAddress] = React.useState('');

  React.useEffect(() => {
    CarouselService.init(api);
    LocationService.init(api);
    fetchLocationList().then(() => {
      if (id) {
        fetchCarousel();
      }
    });
  }, [api]);

  const fetchLocationList = async () => {
    await LocationService.getLocations().then((res) => {
      setLocationList(
        res.map((item) => {
          return {
            id: item.id,
            name: item.name + ' - ' + item.zipcode,
            value: item.id,
          };
        }),
      );
      if (res.length === 1) {
        setLocation(res[0].id);
        setCarousel({ ...carousel, location_id: res[0].id});
      }
    });
  };

  const fetchCarousel = async () => {
    await CarouselService.getCarousel(id).then((res) => {
      setCarousel(res);
      setLocation(res.location_id);
    });
  };

  const handleChange = (prop, event) => {
    setCarousel({ ...carousel, [prop]: event });
  };

  const handleLocationChange = (prop, event) => {
    setCarousel({ ...carousel, [prop]: event });
    setLocation(event);
  };

  const handleSave = async () => {
    if (carousel['ip_address'] && carousel['ip_address'] !== '') {
      try {
        if (id) {
          await CarouselService.updateCarousel(carousel);
        } else {
          await CarouselService.storeCarousel(carousel);
        }
        navigate(`/admin/carousels`);
      } catch (e) {
        alert(typeof e === 'string' ? e : 'An unexpected error occurred');
      }
    } else {
      setIpAddress('error');
    }
  };

  const handleCancel = () => {
    navigate(`/admin/carousels`);
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={6}>
        <Card>
          <CardHeader color='rose' icon>
            <CardIcon color='rose'>
              <CreateIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>{id ? 'Edit' : 'Add'} Carousel</h4>
          </CardHeader>
          <CardBody>
            <form>
              <FormControl fullWidth margin='dense' className={classes.formControl}>
                <TextField
                  margin='dense'
                  id='ip_address'
                  label='API URL'
                  error={ipAddress === 'error'}
                  inputProps={{
                    onChange: (event) => {
                      handleChange('ip_address', event.target.value);
                    },
                    value: carousel.ip_address || '',
                  }}
                  type='text'
                />

                <TextField
                  margin='dense'
                  id='nickname'
                  label='Name'
                  inputProps={{
                    onChange: (event) => {
                      handleChange('nickname', event.target.value);
                    },
                    value: carousel.nickname || '',
                  }}
                  type='text'
                />

                <TextField
                  margin='dense'
                  id='description'
                  label='Description'
                  inputProps={{
                    onChange: (event) => {
                      handleChange('description', event.target.value);
                    },
                    value: carousel.description || '',
                  }}
                  type='text'
                />

                <FormControl fullWidth margin='dense' className={classes.formControl}>
                  <InputLabel>Location</InputLabel>
                  <Select
                    fullWidth
                    label='Location'
                    value={location || ''}
                    required
                    onChange={(e) => {
                      handleLocationChange('location_id', e.target.value);
                    }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                    >
                      Choose Location *
                    </MenuItem>

                    {locationList &&
                      locationList.map((location) => (
                        <MenuItem
                          value={`${location.id}`}
                          key={`${location.id}`}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                        >
                          {location.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>

                <FormControl fullWidth>
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                        checked={!!carousel.is_ev}
                        onChange={(event) => {
                          handleChange('is_ev', event.target.checked);
                        }}
                      />
                    }
                    label='Carousel has EV chargers?'
                  />
                </FormControl>

                <FormControl fullWidth>
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                        checked={!!carousel.is_suv}
                        onChange={(event) => {
                          handleChange('is_suv', event.target.checked);
                        }}
                      />
                    }
                    label='Carousel fits SUVs?'
                  />
                </FormControl>

                <FormControl fullWidth>
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                        checked={!!carousel.is_valet}
                        onChange={(event) => {
                          handleChange('is_valet', event.target.checked);
                        }}
                      />
                    }
                    label='Valet mode?'
                  />
                </FormControl>

                <FormControl fullWidth>
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                        checked={!!carousel.is_reservation_only}
                        onChange={(event) => {
                          handleChange('is_reservation_only', event.target.checked);
                        }}
                      />
                    }
                    label='Reservations only?'
                  />
                </FormControl>

              </FormControl>
              <Button color='success' onClick={handleSave}>
                Update
              </Button>
              <Button color='danger' onClick={handleCancel}>
                Cancel
              </Button>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

CarouselForm.propTypes = {
  id: PropTypes.string,
};

CarouselForm.defaultProps = {
  id: null,
};

import React from 'react';
import MUIDataTable from 'mui-datatables';

import makeStyles from '@mui/styles/makeStyles';
// @mui/icons-material
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardIcon from 'components/Card/CardIcon.js';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { cardTitle } from 'assets/jss/material-dashboard-pro-react.js';
import FeeStructureService from 'services/FeeStructureService';
import useAPI from 'utils/useAPI';
import buttons from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.js';
import { useNavigate } from 'react-router-dom';
import tableStyles from 'assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js';
import { Typography, Tooltip, IconButton } from '@mui/material';
import CraeteIcon from '@mui/icons-material/Create';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
    buttons,
  },
  ...tableStyles,
};

const useStyles = makeStyles(styles);

export default function ReactTables() {
  const api = useAPI();
  const navigate = useNavigate();
  let path = '/fee-structure/edit/';
  const [data, setData] = React.useState([]);
  const [params, setParams] = React.useState({
    pageSize: 10,
    page: 1,
    search: null,
    orderBy: null,
    orderDirection: null
  });

  const fetchFeeStructure = (params) => {
    FeeStructureService.getFeeStructure(params.pageSize, params.page, params.search, params.orderBy, params.orderDirection)
      .then((res) => {
        setParams({
          ...params,
          page: res.page,
          count: res.total
        })
        let d = transform(res.results);
        setData(d);
      })
      .catch();
  };

  React.useEffect(() => {
    FeeStructureService.init(api);
    fetchFeeStructure(params);
  }, [api]);

  function transform(Fee) {
    let transformed_fees = Fee;
    transformed_fees = transformed_fees.map((ses) => {
      return {
        id: ses.id,
        amount: ses.amount,
        duration: ses.duration + ' ' + ses.type,
        actions: (
          <>
            <div display='flex'>
              <Tooltip title='Update FeeStructure' aria-label=''>
                <IconButton
                  onClick={() => {
                    navigate(path + ses.id);
                  }}
                  size='large'
                >
                  <CraeteIcon style={{ color: '#0C3C78' }} />
                </IconButton>
              </Tooltip>
              <Tooltip title='Delete' aria-label=''>
                <IconButton
                  onClick={async () => {
                    await FeeStructureService.deleteFee(ses.id);
                  }}
                  size='large'
                >
                  <DeleteIcon style={{ color: 'red' }} />
                </IconButton>
              </Tooltip>
            </div>
          </>
        ),
      };
    });
    return transformed_fees;
  }

  const classes = useStyles();

  const columns = [
    {
      label: 'Actions',
      name: 'actions',
      options: {
        sort: false
      }
    },
    {
      label: '#',
      name: 'id',
    },
    {
      label: 'Amount ($)',
      name: 'amount',
    },
    {
      label: 'Duration',
      name: 'duration',
    },
  ];
  const options = {
    serverSide: true,
    filter: false, // Hide filters
    print: false, // Hide print
    download: false, // Hide download
    selectableRowsHeader: false, // Hide checkbox in header
    selectableRowsHideCheckboxes: true, // Hide checkbox for every row
    onTableChange: (action, tableState) => {

      const sortCol = tableState.sortOrder?.name;
      const newParams = {
        pageSize: tableState.rowsPerPage,
        page: tableState.page + 1,
        search: tableState.searchText,
        orderBy: sortCol,
        orderDirection: sortCol ? tableState.sortOrder?.direction : null
      };
      if (["changePage", "search", "filterChange", "sort", "changeRowsPerPage"].includes(action)){
        setParams(newParams);
        fetchFeeStructure(newParams);
      }
    },
    customToolbar: () => {
      return (
        <Tooltip title='Add FeeStructure' aria-label=''>
          <IconButton
            onClick={() => {
              navigate('/fee-structure/add');
            }}
            size='large'
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
      );
    },
  };
  return (
    <GridContainer>
      <div className={classes.cardContentLeft}></div>
      <GridItem xs={12}>
        <Card>
          {data && (
            <MUIDataTable
              title={
                <div>
                  <CardIcon color='rose'>
                    <MonetizationOnIcon style={{ color: 'white' }} />{' '}
                  </CardIcon>
                  <Typography variant='h6'>Fee Structure</Typography>
                </div>
              }
              data={data}
              columns={columns}
              options={{...options, count: params.count}}

            />
          )}
        </Card>
      </GridItem>
    </GridContainer>
  );
}

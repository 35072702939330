/*eslint-disable*/
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
// core components
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import { useNavigate } from 'react-router-dom';
import CardIcon from 'components/Card/CardIcon.js';
import CardBody from 'components/Card/CardBody.js';
import ConfigurationService from 'services/ConfigurationService';
import SettingsIcon from '@mui/icons-material/Settings';
import LocationService from 'services/LocationService';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
// style for this view
import styles from 'assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js';
import useAPI from 'utils/useAPI';
const useStyles = makeStyles(styles);

const configurationTypesList = require('../../assets/json/ConfigurationTypes');
function FeeForm({ id = null }) {
  var numberRex = new RegExp('^[0-9]+$');
  const [isEdit] = React.useState(id ? true : false);
  const [label] = React.useState(id ? 'Update Configuration' : 'Add Configuration');

  const [configuration, setConfiguration] = React.useState({
    amount: '',
    name: '',
    type: '',
  });
  const api = useAPI();
  const navigate = useNavigate();

  const [errors, setErrors] = React.useState({
    amount: '',
    name: '',
  });

  const fetchConfiguration = async () => {
    await ConfigurationService.getConfiguration(id).then((res) => {
      console.log('RES', res);
      setConfiguration(res);
    });
  };

  React.useEffect(() => {
    ConfigurationService.init(api);
    if (id) fetchConfiguration();
  }, [api]);

  const handleChange = (prop, val) => {
    setConfiguration({ ...configuration, [prop]: val });
  };

  const registerClick = async () => {
    let error = 0;
    let newErrors = errors;
    ['amount', 'name'].forEach((field) => {
      if (configuration[field] === '') {
        newErrors[field] = 'error';
        error = 1;
      }
    });
    console.log('Error', newErrors);
    setErrors({ ...newErrors });

    if (error) return;

    if (id)
      await ConfigurationService.updateConfiguration({
        id,
        ...configuration,
      });
    else await ConfigurationService.addConfiguration(configuration);
    navigate('/admin/configuration');
  };
  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={6}>
        <Card>
          <CardHeader color='rose' icon>
            <CardIcon color='rose'>
              <SettingsIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>{label}</h4>
          </CardHeader>
          <CardBody>
            <form>
              <TextField
                error={errors.name === 'error'}
                margin='dense'
                id='name'
                label='Name *'
                type='text'
                fullWidth
                value={configuration.name}
                onChange={(event) => {
                  setConfiguration({
                    ...configuration,
                    name: event.target.value,
                  });
                }}
              />
              <TextField
                error={errors.amount === 'error'}
                margin='dense'
                id='amount'
                label='Amount *'
                type='text'
                fullWidth
                value={configuration.amount}
                onChange={(event) => {
                  setConfiguration({
                    ...configuration,
                    amount: event.target.value,
                  });
                }}
              />

              <FormControl fullWidth margin='dense' className={classes.selectFormControl}>
                <InputLabel htmlFor='simple-select' className={classes.selectLabel}>
                  Choose Configuration Type
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  value={configuration.type}
                  onChange={(e) => {
                    handleChange('type', e.target.value);
                  }}
                  // error={errors.type === "error"}
                  inputProps={{
                    name: 'simpleSelect',
                    id: 'simple-select',
                  }}
                  label='Choose Configuration Type'
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    Choose Configuration Type
                  </MenuItem>

                  {configurationTypesList.map((item, index) => (
                    <MenuItem
                      value={`${item.name}`}
                      key={`${item.id}`}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                    >
                      {`${item.name}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <div className={classes.formCategory}>
                <small>*</small> Required fields
              </div>

              <Button color='rose' onClick={registerClick} className={classes.registerButton}>
                {label}
              </Button>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

FeeForm.propTypes = {
  id: PropTypes.string,
};

FeeForm.defaultProps = {
  id: null,
};

export default FeeForm;

/*eslint-disable*/
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
// core components
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import { useNavigate } from 'react-router-dom';
import CardIcon from 'components/Card/CardIcon.js';
import CardBody from 'components/Card/CardBody.js';
import FeeStructureService from 'services/FeeStructureService';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import LocationService from 'services/LocationService';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
// style for this view
import styles from 'assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js';
import useAPI from 'utils/useAPI';
const useStyles = makeStyles(styles);

function FeeForm({ id = null }) {
  const numberRegex = new RegExp('^[0-9]+$');
  const [locationList, setLocationList] = React.useState([]);
  const [submitError, setSubmitError] = React.useState('');
  const [FeeLabel] = React.useState(id ? 'Update Fee Structure' : 'Add Fee Structure');
  const type = [{ name: 'Hour', title: 'Hour(s)' }, { name: 'Minutes' }];
  const [fee, setFee] = React.useState({
    amount: '',
    duration: '',
    type: 'Hour',
    to_all_locations: true,
    location: [],
  });
  const api = useAPI();
  const navigate = useNavigate();

  const [errors, setErrors] = React.useState({
    amount: '',
    duration: '',
    type: '',
  });

  const fetchLocationList = async () => {
    await LocationService.getLocations().then((res) => {
      setLocationList(
        res.map((item) => {
          return {
            id: item.id,
            name: item.name + ' - ' + item.zipcode,
            value: item.id,
          };
        }),
      );
      if (res.length === 1) {
        const defaultLocation = [res[0].id];
        if (!id) {
          setFee({ ...fee, location: defaultLocation });
        }
      }
    });
  };

  const fetchFeeStructure = async () => {
    await FeeStructureService.getFees(id).then((res) => {
      res.location = res.location.map((item) => item.id);
      setFee(res);
    });
  };

  React.useEffect(() => {
    FeeStructureService.init(api);
    LocationService.init(api);
    fetchLocationList();
    if (id) {
      fetchFeeStructure();
    }
  }, [api, id]);

  const handleChange = (prop, val) => {
    setFee({ ...fee, [prop]: val });
    if (verifyLength(val, 1)) {
      setErrors({ ...errors, [prop]: 'success' });
    } else {
      setErrors({ ...errors, [prop]: 'error' });
    }
  };

  const verifyLength = (value, length) => {
    return value.length >= length;

  };

  const verifyNumber = (value) => {
    return numberRegex.test(value) && value.length >= 1;
  };

  const registerClick = async () => {
    let error = 0;
    let newErrors = errors;
    setSubmitError('');
    ['amount', 'duration', 'location'].forEach((field) => {
      if (field === 'location') {
        if (!fee.to_all_locations && !fee['location'].length) {
          newErrors['location'] = 'error';
          error = 1;
        }
      } else if (fee[field] === '' || !numberRegex.test(fee[field])) {
        newErrors[field] = 'error';
        error = 1;
      }
    });
    console.log('Error', newErrors);
    setErrors({ ...newErrors });

    if (error) return;

    try {
      if (id) await FeeStructureService.updateFee(fee);
      else await FeeStructureService.storeFee(fee);
    } catch (e) {
      if (e.response.data.error) {
        setSubmitError(e.response.data.error);
        return;
      }
    }
    navigate('/admin/fee-structure');
  };

  const handleCancel = () => {
    navigate('/admin/fee-structure');
  };

  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={6}>
        <Card>
          <CardHeader color='rose' icon>
            <CardIcon color='rose'>
              <MonetizationOnIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>{FeeLabel}</h4>
          </CardHeader>
          <CardBody>
            <form>
              <TextField
                error={errors.amount === 'error'}
                margin='dense'
                id='amount'
                label='Amount ($) *'
                type='text'
                fullWidth
                value={fee.amount}
                onChange={(event) => {
                  if (verifyNumber(event.target.value)) {
                    setErrors({ ...errors, amount: 'success' });
                  } else {
                    setErrors({ ...errors, amount: 'error' });
                  }
                  setFee({ ...fee, amount: event.target.value });
                }}
              />
              <TextField
                error={errors.duration === 'error'}
                margin='dense'
                id='duration'
                label='Duration *'
                type='text'
                fullWidth
                value={fee.duration}
                onChange={(event) => {
                  if (verifyNumber(event.target.value)) {
                    setErrors({ ...errors, amount: 'success' });
                  } else {
                    setErrors({ ...errors, amount: 'error' });
                  }
                  setFee({ ...fee, duration: event.target.value });
                }}
              />

              <FormControl className={classes.selectFormControl} margin='dense' fullWidth>
                <InputLabel>Duration Type *</InputLabel>
                <Select
                  value={fee.type}
                  onChange={(e) => {
                    handleChange('type', e.target.value);
                  }}
                  error={errors.type === 'error'}
                  label='Duration Type *'
                >
                  <MenuItem
                    disabled
                    value=''
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    Choose Type *
                  </MenuItem>

                  {type.map((type) => (
                    <MenuItem
                      value={`${type.name}`}
                      key={`${type.name}`}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                    >
                      {`${type.title || type.name}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!fee.to_all_locations}
                    onChange={() => {
                      setFee({
                        ...fee,
                        to_all_locations: event.target.checked,
                      });
                    }}
                  />
                }
                label='Applies to All Locations'
              />

              {!fee.to_all_locations && (
                <FormControl
                  fullWidth
                  className={classes.selectFormControl}
                >
                  <InputLabel htmlFor='multiple-select' className={classes.selectLabel}>
                    Choose Specific Location to Apply Fee Structure
                  </InputLabel>
                  <Select
                    multiple
                    value={fee.location}
                    error={errors.location === 'error'}
                    onChange={(e) => {
                      setFee({ ...fee, location: e.target.value });
                      if (verifyLength(e.target.value, 1)) {
                        setErrors({ ...errors, location: 'success' });
                      } else {
                        setErrors({ ...errors, location: 'error' });
                      }
                    }}
                    MenuProps={{ className: classes.selectMenu }}
                    classes={{ select: classes.select }}
                    inputProps={{
                      name: 'multipleSelect',
                      id: 'multiple-select',
                    }}
                    label='Choose Specific Location to Apply Fee Structure'
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                    >
                      Choose Location
                    </MenuItem>
                    {locationList &&
                      locationList.map((location) => (
                        <MenuItem
                          value={`${location.id}`}
                          key={`${location.id}`}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                        >
                          {`${location.name}`}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}

              <div className={classes.formCategory}>
                <small>*</small> Required fields
              </div>

              {submitError && <div style={{ color: 'red' }}>{submitError}</div>}

              <Button color='danger' onClick={handleCancel} className={classes.registerButton}>
                Cancel
              </Button>

              <Button color='success' onClick={registerClick} className={classes.registerButton}>
                {FeeLabel}
              </Button>

            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

FeeForm.propTypes = {
  id: PropTypes.string,
};

FeeForm.defaultProps = {
  id: null,
};

export default FeeForm;
